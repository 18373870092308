import React, {useEffect} from "react";
import classNames from 'classnames';
import { TicketSearchHeader } from "components/ticket-search-header/TicketSearchHeader";
import styles from "./TicketSearch.module.scss";
import { TicketSearchFilters } from "components/ticket-search-filters/TicketSearchFilters";
import { TicketSearchTable } from "components/ticket-search-table/TicketSearchTable";
import { closeAlert, fetchUsers, fetchTickets, fetchUnitCodes } from "store/ticketSearchSlice";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AlertMessage, AlertMessageParams } from "components/alert-message/AlertMessage";
import useURLParams from "hooks/useURLParams";
import { filtersToParams, sortingQueryParamsAdapter } from "utils/list-params";
import { filterQueryParamsAdapter } from "./TicketSearch.helpers";
import { DEFAULT_FILTER_VALUES, ListFilterEnum, TicketInclude, ActionLinkEnum } from "appConstants";
import type { GetListResponse, GetTicketsResponse, PageQueryParams, SearchFilterOptions, SearchOrderByQueryParams, SearchSelectedFilterValues, TicketingServiceFilter } from "typing/request";
import type { TicketV1Attributes, UnitV2Attributes, UserConnectAttributes } from "typing/dto";
import { ConnectService } from "services/ConnectService";
import { userToFilterOption } from "components/ticket-search-filters/TicketSearchFilter.helpers";
import { isAdmin } from "utils/helpers";
import { TicketSearchSkeleton } from "components/ticket-search-skeleton/TicketSearchSkeleton";
import { ActionBar } from "components/action-bar/ActionBar";
import { Spinner } from "components/spinner/Spinner";

export const TicketSearch: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { searchParams, getParamsByCategory } = useURLParams();
    const tickets = useSelector((state: RootState) => state.ticketSearch.tickets);
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );
    const orderBy: SearchOrderByQueryParams = useSelector((state: RootState) => state.ticketSearch.orderBy)
    const page: PageQueryParams = useSelector((state: RootState) => state.ticketSearch.page);
    const isLoading: boolean = useSelector((state: RootState) => state.ticketSearch.isLoading);
    const reload: boolean = useSelector((state: RootState) => state.ticketSearch.reload);
    const alertMessageParams: AlertMessageParams = useSelector((state: RootState) => state.ticketSearch.alertMessageParams);
    const users: UserConnectAttributes[] = useSelector((state: RootState) => state.ticketSearch.users);
    const units: UnitV2Attributes[] = useSelector((state: RootState) => state.ticketSearch.units);
    const currentConnectUser = ConnectService.getInstance().currentUser;
    const isFirstLoad = !tickets.length && !Object.keys(selectedFilterValues).length && isLoading;

    // Set the current logged user as default manager filter value
    if (currentConnectUser) DEFAULT_FILTER_VALUES[ListFilterEnum.MANAGER_ID] = [userToFilterOption(currentConnectUser)];

    // Action buttons that are allowed to be displayed in the action bar
    const allowedActionButtons = {
        [ActionLinkEnum.ADD_TICKET]: true
    };

    // Extract filter parameters from the URL query
    const filterQueryParams = getParamsByCategory('filter');
    // Extract pagination parameters from the URL query
    const pageQueryParams = getParamsByCategory('page');
    // Extract sorting parameter from the URL query
    const sortingQueryParams = searchParams.get('sort');

    const fetchData = async (
        filterQueryParams: SearchFilterOptions = {},
        pageQueryParams: PageQueryParams = {},
        sortingQueryParams: string | null,
    ) => {
        const result = await dispatch(fetchTickets({
            page: {...pageQueryParams, ...page},
            filter: {
                ...filtersToParams(DEFAULT_FILTER_VALUES),
                ...filterQueryParamsAdapter(filterQueryParams, selectedFilterValues)
            } as TicketingServiceFilter,
            sort: sortingQueryParamsAdapter(sortingQueryParams, orderBy),
            include: TicketInclude.COMMENTS
        }));

        const listResponse = result.payload as GetTicketsResponse;

        const userIdList = getUsers(listResponse, filterQueryParams as TicketingServiceFilter).join();
        const unitIdList = getUnits(listResponse, filterQueryParams as TicketingServiceFilter).join();

        const fetchPromises = [];
        if (userIdList) fetchPromises.push(dispatch(fetchUsers(userIdList)));
        if (unitIdList) fetchPromises.push(dispatch(fetchUnitCodes(unitIdList)));
        await Promise.all(fetchPromises);
    };

    const getUsers = (
        listResponse: GetTicketsResponse,
        filterQueryParams: TicketingServiceFilter
    ): string[] => {
        const {assigned_to, manager_id, follower_id, created_by} = filterQueryParams;
        const assignedToList = listResponse.data.filter(Boolean).map(ticket => String(ticket.attributes.assigned_to));
        const createdByList = listResponse.data.filter(Boolean).map(ticket => String(ticket.attributes.created_by));
        const commentAuthorList = listResponse.data
            .map(ticket => String(ticket.includedMap?.comments[0]?.attributes.user_id || ""))
            .filter(Boolean);
        const userArr = [assigned_to, manager_id, follower_id, created_by].filter(Boolean).map(String);
        return [
            ...assignedToList,
            ...createdByList,
            ...userArr,
            ...commentAuthorList,
        ];
    };

    const getUnits = (
        listResponse: GetListResponse<TicketV1Attributes>,
        filterQueryParams: TicketingServiceFilter
    ): string[] => {
        const unitIdList = listResponse.data.filter(Boolean).map(ticket => String(ticket.attributes.unit_id));
        if (filterQueryParams?.unit_id) unitIdList.push(String(filterQueryParams.unit_id));
        return unitIdList;
    };

    useEffect(() => {
        // Fetch the data with the parameters obtained from the URL
        fetchData(filterQueryParams, pageQueryParams, sortingQueryParams);
    },[reload]); // eslint-disable-line react-hooks/exhaustive-deps

    // If the data is not loaded yet, show the skeleton loader
    if (isFirstLoad) {
        return (
            <>
                <TicketSearchSkeleton className={classNames("max-width", "extra")}/>;
            </>
        )
    // If the data is loaded, show the ticket search page
    } else {
        return (
            <div className={classNames(styles.container,"max-width", "extra")}>
                { isAdmin() &&
                    <>
                        <ActionBar unitId={""} allowedButtons={allowedActionButtons}/>
                    </> 
                }
                {/* Show the spinner if the data is loading */}
                {isLoading && 
                    <div className={styles.spinner}>
                        <Spinner />
                    </div>
                }
                <TicketSearchHeader/>
                <TicketSearchFilters filterQueryParams={filterQueryParams} users={users} units={units}/>
                <AlertMessage params={alertMessageParams} onClose={() => dispatch(closeAlert())}/>
                <TicketSearchTable/>
            </div>
        );
    }
}
