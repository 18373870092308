import React, { useEffect, useState } from "react";
import styles from "./TicketSearchFilters.module.scss";
import { MultiSelectFilter } from "components/multi-select-filter/MultiSelectFilter";
import { RootState, useAppDispatch } from "store/store";
import {openErrorAlert, setPage, setReload, setSelectedFilterValues} from "store/ticketSearchSlice";
import { TicketingService } from "services/TicketingService";
import { UnitService } from "services/UnitService";
import { useSelector } from "react-redux";
import { CLOSE_STATUS, ListFilterEnum, OPEN_STATUS, DEFAULT_SEARCH_PAGINATION_VALUES } from "appConstants";
import { getDefaultUsers, getSelectedDates, getSelectedOverdue, getSelectedStatus, getSelectedUnits, getSelectedUsers, transformUnitToFilterItem, getSelectedSeverity, getSelectedVisibility } from "./TicketSearchFilter.helpers";
import { TextInputFilter } from "components/text-input-filter/TextInputFilter";
import { DatePickerFilter } from "components/date-picker-filter/DatePickerFilter";
import moment from "moment";
import { OverdueFilter } from "components/ticket-search-overdue-filter/OverdueFilter";
import type { DataType, FilterOption, SearchFilterOptions, SearchSelectedFilterValues } from "typing/request";
import type { AssignableUserAttributes, UnitV2Attributes, UserConnectAttributes } from "typing/dto";
import { ConnectService } from "services/ConnectService";
import { TicketEntity } from "entities/ticket";
import useURLParams from "hooks/useURLParams";
import { resetURLPagination } from "utils/url-params";

interface ITicketSearchFiltersProps {
    filterQueryParams?: SearchFilterOptions,
    units?: UnitV2Attributes[],
    users?: UserConnectAttributes[],
}

export const TicketSearchFilters: React.FC<ITicketSearchFiltersProps> = ({ filterQueryParams = {}, units = [], users = [] }: ITicketSearchFiltersProps) => {
    const dispatch = useAppDispatch();
    const { setURLParams } = useURLParams();
    const [managerOptions, setManagerOptions] = useState([] as FilterOption[]);
    const [assigneeOptions, setAssigneeOptions] = useState([] as FilterOption[]);
    const [followerOptions, setFollowerOptions] = useState([] as FilterOption[]);
    const [creatorOptions, setCreatorOptions] = useState([] as FilterOption[]);
    const [unitOptions, setUnitOptions] = useState([] as FilterOption[]);

    // Filter by status
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );
    const ticketingService = TicketingService.getInstance();
    const unitService = UnitService.getInstance();
    const currentConnectUser = ConnectService.getInstance().currentUser;

    useEffect(() => {
        /** 
         * Initializes filter values from query parameters. 
         * 
         * IMPORTANT: It does NOT has deps.
        */
       const initializeFilterValues = () => {
            const selectedStatus = getSelectedStatus(filterQueryParams, TicketEntity.statusOptions);
            const selectedDates = getSelectedDates(filterQueryParams);
            const selectedOverdue = getSelectedOverdue(filterQueryParams);
            const defaultUsers = getDefaultUsers(filterQueryParams, currentConnectUser);
            const selectedSeverity = getSelectedSeverity(filterQueryParams, TicketEntity.severityOptions);
            const selectedVisibility = getSelectedVisibility(filterQueryParams, TicketEntity.visibilityOptions);

            dispatch(setSelectedFilterValues({
                ...selectedFilterValues,
                ...defaultUsers,
                ...selectedStatus,
                ...selectedDates,
                ...selectedOverdue,
                ...selectedSeverity,
                ...selectedVisibility,
            }));
        }
        initializeFilterValues();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        /**
         * Initializes filter values from query parameters.
         * 
         * This function retrieves the selected users and units from query parameters
         * then dispatches an action to update the selected filter values.
         * 
         * IMPORTANT: It has deps.
        */
        if (!users.length && !units.length) return;
        const initializeFilterValues = () => {
            const selectedUsers = getSelectedUsers(filterQueryParams, users);
            const selectedUnits = getSelectedUnits(filterQueryParams, units);
            dispatch(setSelectedFilterValues({
                ...selectedFilterValues,
                ...selectedUsers,
                ...selectedUnits,
            }));
        }
        initializeFilterValues();
    }, [users, units]) // eslint-disable-line react-hooks/exhaustive-deps

    // Reset pagination if any filter change
    const resetPagination = () => {
        resetURLPagination(setURLParams);
        dispatch(setPage({
            limit: DEFAULT_SEARCH_PAGINATION_VALUES.LIMIT,
            number: DEFAULT_SEARCH_PAGINATION_VALUES.NUMBER
        }));
    }

    const updateList = (selectedList: FilterOption | FilterOption[], filterId: string) => {
        const newFilters = {
            ...selectedFilterValues,
            [filterId]: selectedList,
        };
        resetPagination();
        dispatch(setSelectedFilterValues(newFilters));
        dispatch(setReload());
    };

    const errorHandler = (fetcherContext: string) => {
        dispatch(openErrorAlert(
            `Error on fetching ${fetcherContext.toUpperCase()}, please get in touch with your support team or try again.`
        ));
    }

    const fetchUnits = async (searchString: string): Promise<FilterOption[] | void> => {
        try {
            const units = await unitService.getUnitsByParams({
                filter: {
                    unit_code: {
                        startswith: searchString.toUpperCase()
                    }
                }
            }, {requestCancellation: true});
            return transformUnitToFilterItem(units)
        } catch (e) {
            errorHandler('units');
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    };

    const fetchAssignees = async (searchString: string, errorContext: string): Promise<FilterOption[] | void> => {
        try {
            const assignees = await ticketingService.getAssignees({
                search_term: searchString
            }, {}, {requestCancellation: true});
            return assignees.data.map((user: DataType<AssignableUserAttributes>) => {
                const { attributes, id } = user;
                const { first_name, last_name } = attributes;
                return {
                    name: `${first_name} ${last_name}`,
                    value: id
                }
            });
        } catch (e) {
            errorHandler(errorContext);
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    };

    // CREATION RANGE
    const startDate = moment(selectedFilterValues?.creation_date_gte?.value).toDate();
    const endDate = moment(selectedFilterValues?.creation_date_lte?.value).toDate();

    // STATUS
    const defaultStatus = [
        OPEN_STATUS,
        CLOSE_STATUS,
        ...TicketEntity.statusOptions
    ] as FilterOption[];
    const [statusOptions, setStatusOptions] = useState(defaultStatus);

    return (
        <div data-testid="ticket-search-filters" >
            <div className={styles.content}>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="MANAGER"
                        id={ListFilterEnum.MANAGER_ID}
                        options={managerOptions}
                        onFetchData={(searchString: string) => fetchAssignees(searchString, "managers")}
                        onUpdate={updateList}
                        setOptions={setManagerOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.MANAGER_ID]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="ASSIGNEE"
                        id={ListFilterEnum.ASSIGNED_TO}
                        options={assigneeOptions}
                        onFetchData={(searchString: string) => fetchAssignees(searchString, "assignees")}
                        onUpdate={updateList}
                        setOptions={setAssigneeOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.ASSIGNED_TO]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="FOLLOWER"
                        id={ListFilterEnum.FOLLOWER_ID}
                        options={followerOptions}
                        onFetchData={(searchString: string) => fetchAssignees(searchString, "followers")}
                        onUpdate={updateList}
                        setOptions={setFollowerOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.FOLLOWER_ID]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="UNIT"
                        id={ListFilterEnum.UNIT_ID}
                        options={unitOptions}
                        onFetchData={fetchUnits}
                        onUpdate={updateList}
                        setOptions={setUnitOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.UNIT_ID]}
                    />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <DatePickerFilter
                        id={ListFilterEnum.START_DATE}
                        title="Start Date"
                        onUpdate={updateList}
                        className={styles.datepicker}
                        date={startDate}
                        maxDate={endDate}
                    />
                </div>
                <div className={styles.item}>
                    <DatePickerFilter
                        id={ListFilterEnum.END_DATE}
                        title="End Date"
                        date={endDate}
                        onUpdate={updateList}
                        className={styles.datepicker}
                        minDate={startDate}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Status"
                        id={ListFilterEnum.STATUS}
                        options={statusOptions}
                        onUpdate={updateList}
                        selectedValues={selectedFilterValues[ListFilterEnum.STATUS]}
                        minimumCharacters={0}
                        isGroupingEnabled={true}
                        defaultOptions={defaultStatus}
                        setOptions={setStatusOptions}
                    />
                </div>
                <div className={styles.item}>
                    <TextInputFilter
                        title="Ticket Name"
                        id={ListFilterEnum.TITLE}
                        onUpdate={updateList}
                    />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Severity"
                        id={ListFilterEnum.SEVERITY}
                        options={TicketEntity.severityOptions}
                        onUpdate={updateList}
                        selectedValues={selectedFilterValues[ListFilterEnum.SEVERITY]}
                        minimumCharacters={0}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Created By"
                        id={ListFilterEnum.CREATED_BY}
                        options={creatorOptions}
                        onFetchData={(searchString: string) => fetchAssignees(searchString, "creators")}
                        onUpdate={updateList}
                        setOptions={setCreatorOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.CREATED_BY]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Visibility"
                        id={ListFilterEnum.VISIBILITY}
                        options={TicketEntity.visibilityOptions}
                        onUpdate={updateList}
                        selectedValues={selectedFilterValues[ListFilterEnum.VISIBILITY]}
                        minimumCharacters={0}
                    />
                </div>
                <div className={styles.item}>
                    <OverdueFilter
                        title="Overdue"
                    />
                </div>
            </div>
        </div>
    );
}

