import React, {useEffect} from "react";
import classNames from 'classnames';
import styles from "./TicketSearchSlim.module.scss";
import { closeAlert, fetchUsers, fetchTickets, setSelectedFilterValues } from "store/ticketSearchSlice";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "components/spinner/Spinner";
import { GetTicketsResponse, PageQueryParams, SearchFilterOptions, SearchOrderByQueryParams } from "typing/request";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AlertMessage, AlertMessageParams } from "components/alert-message/AlertMessage";
import { filtersToParams, SortingQueryParamsAdapter } from "utils/list-params";
import { TicketSearchFiltersSlim } from "components/ticket-search-filters-slim/TicketSearchFiltersSlim";
import { TicketSearchTableSlim } from "components/ticket-search-table-slim/TicketSearchTableSlim";
import { ActionLinkEnum, DEFAULT_SLIM_FILTER_VALUES, TicketInclude } from "appConstants";
import useURLParams from "hooks/useURLParams";
import { TicketSearchSkeleton } from "components/ticket-search-skeleton/TicketSearchSkeleton";
import { Configuration } from "core/configuration";
import useResizeObserver from "hooks/useResizeObserver";
import { ActionBar } from "components/action-bar/ActionBar";

export const TicketSearchSlim: React.FC = () => {
    useResizeObserver(Configuration.innerRootId);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { getParamsByCategory } = useURLParams();

    const tickets = useSelector((state: RootState) => state.ticketSearch.tickets);
    const selectedFilterValues = useSelector((state: RootState) => state.ticketSearch.selectedFilterValues);
    const orderBy: SearchOrderByQueryParams = useSelector((state: RootState) => state.ticketSearch.orderBy)
    const page: PageQueryParams = useSelector((state: RootState) => state.ticketSearch.page);
    const isLoading: boolean = useSelector((state: RootState) => state.ticketSearch.isLoading);
    const reload: boolean = useSelector((state: RootState) => state.ticketSearch.reload);
    const alertMessageParams: AlertMessageParams = useSelector((state: RootState) => state.ticketSearch.alertMessageParams);
    
    const allowedActionButtons = {
        [ActionLinkEnum.ALL_TICKETS_BY_UNIT]: true,
        [ActionLinkEnum.ADD_TICKET]: true
    };

    // Extract filter parameters from the URL query
    const filterQueryParams: SearchFilterOptions  = getParamsByCategory('filter');

    const fetchData = async () => {
        const result = await dispatch(fetchTickets({
            page,
            filter: {
                ...filtersToParams(DEFAULT_SLIM_FILTER_VALUES),
                ...filtersToParams(selectedFilterValues),
                ...(filterQueryParams?.unit_id && {unit_id: Number(filterQueryParams.unit_id)}) 
            },
            sort: SortingQueryParamsAdapter.convertSortToString(orderBy),
            include: TicketInclude.COMMENTS
        }));

        const listResponse = result.payload as GetTicketsResponse;
        if (!listResponse || !listResponse?.data?.length) return;

        // Adds all needed users
        const assignedToList = listResponse.data.map(ticket => ticket.attributes.assigned_to);
        const commentAuthorList = listResponse.data.map(ticket => ticket.includedMap?.comments[0]?.attributes.user_id);
        await dispatch(
            fetchUsers(
                [
                    ...assignedToList,
                    ...commentAuthorList
                ]
                .join(',')
            )
        );
    };

    /**
     * Loads the component's data when it mounts
     */
    useEffect(() => {
        fetchData();
    },[reload]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Initializes default values for the filters when the component mounts
     */
    useEffect(() => {
        dispatch(setSelectedFilterValues(DEFAULT_SLIM_FILTER_VALUES));
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    // Display loader until
    if ((!tickets.length && !Object.keys(selectedFilterValues).length) && isLoading) {
        return <TicketSearchSkeleton />;
    }

    return (
        <div data-testid="ticket-search-slim" className={classNames(styles.container,"max-width")}>
            {isLoading && <div className={styles.spinner}>
                <Spinner />
            </div>} 
            <ActionBar unitId={filterQueryParams?.unit_id ?? ""} allowedButtons={allowedActionButtons}/>
            <TicketSearchFiltersSlim />
            <AlertMessage params={alertMessageParams} onClose={() => dispatch(closeAlert())}/>
            <TicketSearchTableSlim/>
        </div>
    );
}
