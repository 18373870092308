import {DEFAULT_SEARCH_PAGINATION_VALUES} from "appConstants";
import {ISetURLParams} from "hooks/useURLParams";

/**
 * Method to reset pagination parameters in the URL
 * @param setSearchParams
 */
export const resetURLPagination = (setSearchParams: ISetURLParams): void => {
    setSearchParams({
        "page[number]": String(DEFAULT_SEARCH_PAGINATION_VALUES.NUMBER),
        "page[limit]": String(DEFAULT_SEARCH_PAGINATION_VALUES.LIMIT),
    });
};
