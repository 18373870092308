/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { TicketBox } from "components/ticket-box/TicketBox";
import { TicketEditDueDateModal } from "components/ticket-edit-due-date-modal/TicketEditDueDateModal";
import moment from "moment";
import {RootState} from "store/store";
import isNil from "lodash/isNil";
import Tooltip from '@mui/material/Tooltip';
import styles from "./TicketEditDueDate.module.scss";
import { ReactComponent as WarningBox } from "assets/icons/warning-box.svg";
import { useModalScroll } from "hooks/useModalScroll";
import {useSelector} from "react-redux";


interface TicketEditDueDateProps {
    ticketId: number;
    isEditable: boolean;
}

export const TicketEditDueDate: React.FC<TicketEditDueDateProps> = ({ticketId, isEditable}: TicketEditDueDateProps) => {
    const dueDate = useSelector((state: RootState) => state.ticketEdit.ticket?.completion_date);

    const [isModalOpen, setIsModalOpen] = useModalScroll(false);
    const isDateInPast = moment(dueDate).isBefore(moment(), "days");

    return (
        <TicketBox>
            {isModalOpen && !isNil(ticketId) &&
                <TicketEditDueDateModal ticketId={ticketId} setIsModalOpen={setIsModalOpen} />
            }

            <div className="box-link">
                <button
                    className="btn-link"
                    data-testid="due-date-modal-opener"
                    onClick={() => setIsModalOpen(true)}
                    disabled={!isEditable}
                >
                    Edit Due Date
                </button>
            </div>
            <div data-testid="due-date-title" className="box-title">Due Date:</div>
            <div data-testid="due-date-value" className="box-value">
                {
                    isNil(dueDate) ? (
                        <span data-testid="due-date-not-found">Not found</span>
                    ) : moment(dueDate).format("MM/DD/YY")
                }

                {isDateInPast &&
                    <span data-testid="due-date-in-past-warn-icon">
                        <Tooltip title={"Due Date is in the past."}>
                            <span className={styles["warning-box-icon"]}><WarningBox /></span>
                        </Tooltip>
                    </span>
                }

            </div>
        </TicketBox>
    );
}
