import {fetchTicket, IFetchTicketThunkResponse} from "./ticketEditSlice";
import {createAsyncThunk, createSlice, Draft} from "@reduxjs/toolkit";
import {TicketingService} from "services/TicketingService";
import {TicketSalesforceParentV1Attributes, TicketV1Attributes} from "typing/dto";
import {getErrorMessage} from "utils/message";

// Service Singletons
const ticketingService = TicketingService.getInstance();

export const patchTicketHeader = createAsyncThunk<any, any, any>(
    "ticketEditHeader/patchTicketHeader",
    async ({id, attributes}: {id: number; attributes: Partial<TicketV1Attributes>}, {rejectWithValue}): Promise<TicketV1Attributes> => {
        try {
            return ticketingService.patchTicket(id, attributes);
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export interface ITicketEditHeaderState {
    isSnoozed: boolean;
    showUnpair: boolean;
    salesforceParent: TicketSalesforceParentV1Attributes[];
}

const setValuesByTicket = (state: Draft<ITicketEditHeaderState>, payload: TicketV1Attributes) => {
    const {sf_case_id, snooze} = payload;
    state.isSnoozed = !!snooze;
    state.showUnpair = !!sf_case_id;
};

const ticketEditHeaderSlice = createSlice({
    name: "ticketEditHeader",
    initialState: {
        isSnoozed: false,
        showUnpair: false,
        salesforceParent: [],
    } as ITicketEditHeaderState,
    reducers: {
        setHeaderTicket: (state: ITicketEditHeaderState, action) => {
            const {payload}: {payload: TicketV1Attributes} = action;
            setValuesByTicket(state, payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(patchTicketHeader.fulfilled, (state: ITicketEditHeaderState, {payload}: {payload: TicketV1Attributes}) => {
            setValuesByTicket(state, payload);
        });
        builder.addCase(fetchTicket.fulfilled, (state: ITicketEditHeaderState, {payload}: {payload: IFetchTicketThunkResponse}) => {
            state.salesforceParent = payload.salesforceParent;
        });
    },
});

export const {setHeaderTicket} = ticketEditHeaderSlice.actions;

export default ticketEditHeaderSlice.reducer;
