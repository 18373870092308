import {ListFilterEnum, STATUS_MAP} from "appConstants";
import TicketingService from "services/TicketingService";
import type {TicketV1Attributes} from "typing/dto";
import type {ConfigOptions, FilterOption} from "typing/request";

export class TicketEntity {
    private ticket: TicketV1Attributes;

    // Hardcoded List of Requested By Alternatives
    static readonly requestedByOptions = {
        "1": "Owner",
        "2": "Guest",
        "3": "Staff",
    };

    // Hardcoded mapping for Verbose Assignment Reasons
    static readonly reasonForAssignment = {
        cross_coverage: "Cross Coverage",
        coverage_partner: "Coverage Partner",
        manager: "Manager",
        manual: "Manually Assigned",
        standard_availability: "Standard Availability",
        zone_disposition_default: "Disposition Default",
        non_urgent_unit_manager_default: "Non-Urgent Unit Manager Default",
        non_urgent_zone_disposition_default: "Non-Urgent Disposition Default",
        no_unit_manager: "No Unit Manager or Disposition Default",
    };

    // Hardcoded mapping for days of recurrence
    static readonly frequencyOfRecurrence = {
        "1": "Daily",
        "7": "Weekly",
        "30": "Monthly (Every 30 Days)",
        "90": "Quarterly (Every 90 Days)",
        "180": "Semi-Annually (Every 180 Days)",
        "365": "Annually",
    };

    // Hardcoded Mappings for Reopen types
    static readonly frequencyReopenTypes = {
        last_open_date: "Created Date / Last Open Date",
        last_due_date: "Last Due Date",
        last_completed_date: "Completed Date / Closed Date",
        frequency_start_date_cycle: "Custom",
    };

    static readonly frequencyReopenAfterDuedate = {
        "30": "30 Days After Reopen Date",
        "21": "3 Weeks After Reopen Date",
    };

    constructor(ticket: TicketV1Attributes) {
        this.ticket = ticket;
    }

    /**
     * Checks if a Ticket is in Open state
     */
    isOpen(): boolean {
        return !this.ticket.void && !this.ticket.completed_date;
    }

    /**
     * Get's who Requested the Ticket
     */
    getRequestedBy(): {id: number; name: string} {
        if (!this.ticket.requested_by || !Object.keys(TicketEntity.requestedByOptions).includes(this.ticket.requested_by.toString()))
            return {id: 0, name: ""};

        return {
            id: this.ticket.requested_by,
            // @ts-ignore
            name: TicketEntity.requestedByOptions[this.ticket.requested_by.toString()],
        };
    }

    /**
     * Gets Verbose Reason For Assignment
     */
    getReasonForAssignment(): string {
        if (!Object.keys(TicketEntity.reasonForAssignment).includes(this.ticket.reason_for_assignment)) return "";
        // @ts-ignore
        return TicketEntity.reasonForAssignment[this.ticket.reason_for_assignment];
    }

    /**
     * Gets Verbose Frequency Of Recurrence
     */
    getFrequencyOfRecurrence(): string {
        const frequency = this.ticket.frequency ? this.ticket.frequency.toString() : "0";

        // @ts-ignore
        return TicketEntity.frequencyOfRecurrence[frequency];
    }

    /**
     * Check if this a recurrent ticket
     */
    static isRecurrent(frequency: number | null): boolean {
        return Number(frequency) > 0;
    }

    /**
     * List of status options
     */
    public static get statusOptions(): FilterOption[] {
        return Array.from(STATUS_MAP, ([key, value]) => ({value: key, name: value}));
    }

    /**
     * List of severity options
     */
    public static get severityOptions() {
        return TicketEntity.getFilterOptions(ListFilterEnum.SEVERITY);
    }

    /**
     * List of severity options
     */
    public static get visibilityOptions() {
        return TicketEntity.getFilterOptions(ListFilterEnum.VISIBILITY);
    }

    /**
     * Retrieves filter options from the configuration file (`config.json`).
     * This method maps configuration values to a consistent format for use in filters.
     */
    public static getFilterOptions(configName: keyof ConfigOptions): FilterOption[] {
        const ticketingService = TicketingService.getInstance();
        const config = ticketingService.getConfig(configName) || [];
        return config.map((filterOption) => ({value: filterOption.value, name: filterOption.text}));
    }
}
