import moment from "moment";
import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import { LIST_DATE_FORMAT } from "appConstants";
import { ExtraTextTooltip } from "components/extra-text-tooltip/ExtraTextTooltip";
import { TicketCommentV1Attributes } from "typing/dto";
import styles from "./TicketSearchCommentTooltip.module.scss";

export interface ITicketSearchCommentTooltipProps {
    comment: TicketCommentV1Attributes,
    dateFormat?: string,
    maxLength?: number,
    username: string
}
export const TicketSearchCommentTooltip: React.FC<ITicketSearchCommentTooltipProps> = ({comment, dateFormat = LIST_DATE_FORMAT, maxLength = 50, username}: ITicketSearchCommentTooltipProps) => {
    const { note } = comment; 
    const defaultUser = "System";
    const safeLink = parse(DOMPurify.sanitize(maxLength < note.length ? note.slice(0, maxLength) + "..." : note));
    const safeNote = parse(DOMPurify.sanitize(note));
    return (
        <ExtraTextTooltip altText={
            <aside data-testid="comment-tooltip-content" className={styles["tooltip-content"]}>
            {/* Tooltip */}
                <p><b>By:</b> {comment.user_id? username : defaultUser}</p>
                <p><b>At:</b> {moment(comment.timestamp).format(dateFormat)}</p>
                <b>Comment:</b>
                <p>
                    {safeNote}
                </p>
            </aside>
        }>
            <span className={styles["link-content"]}>
            {/* Link */}
                <b>{moment(comment.timestamp).format(dateFormat)}</b>
                <p>
                    {safeLink}
                </p>
            </span>
        </ExtraTextTooltip>
    );
}
