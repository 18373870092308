import moment from "moment";
import type {EventLogInfo} from "typing/message";
import type {FilterOption, SearchOrderByQueryParams, SearchSelectedFilterValues} from "typing/request";

export const ALERT_TEMPLATE_SUCCESS = "The {0} {1} been {2} successfully.";
export const ALERT_TEMPLATE_ERROR = "The {0} {1} not been {2}. Please try again or contact support. Error: {3}.";
export const ALERT_GENERIC_REQUEST_ERROR = "Ooops! Something happened while processing the request";
export const NOT_FOUND_TEXT = "Not found";
export const LIST_DATE_FORMAT = "MMM DD,YYYY HH:mm:ss";
export const OWNER_COMMENT_PLACEHOLDER: string = "Add a new owner visible comment here. Please use a style and tone appropriate for customers.";
export const TICKET_ADD_TIMES_IS_MAINTENANCE: number = 1;
export const TICKET_FETCH_REMINDER_IS_MAINTENANCE: number = 1;
export const TICKET_FETCH_REMINDER_UPCOMING: number = 0;
export const UNKNOWN_ERROR = "Unknown error";

export const enum VisibilityStatusEnum {
    INTERNAL = "internal",
    OWNER = "owner",
}

export const enum TicketStatusEnum {
    OPEN = "open",
    STARTED = "started",
    COMPLETED = "completed",
    CANCELED = "canceled",
}

export const enum HistoryFilterOptionEnum {
    ACTIVITY = "Activity",
    STAFF_ONLY = "Staff Only",
    STAFF_OWNER = "Staff & Owner",
}

export const enum HostApplication {
    ADMIN = "admin",
    SALESFORCE = "salesforce",
}

// Same as Host Application but focused on Url Building
export const enum HostApplicationBuilderTypes {
    ADMIN_LEGACY = "admin_legacy",
    ADMIN = HostApplication.ADMIN,
    SALESFORCE = HostApplication.SALESFORCE,
}

export const EVENT_LOG_DATA: Record<string, EventLogInfo> = {
    /* Ticket Edit */
    // Assignable user
    TICKET_ASSIGNABLE_USER_GET: {target: "assignable user", action: "found", logEvent: "TICKET_ASSIGNABLE_USER_GET"},
    // Attachment
    TICKET_ATTACHMENT_ADD: {target: "attachment", action: "added", logEvent: "TICKET_ATTACHMENT_ADD"},
    TICKET_ATTACHMENT_DELETE: {target: "attachment", action: "deleted", logEvent: "TICKET_ATTACHMENT_DELETE"},
    // Comment
    TICKET_COMMENT_ADD: {target: "comment", action: "added", logEvent: "TICKET_COMMENT_ADD"},
    // Description
    TICKET_DESCRIPTION_UPDATE: {target: "description", action: "updated", logEvent: "TICKET_DESCRIPTION_UPDATE"},
    // Details
    TICKET_DETAILS_UPDATE: {target: "details", action: "updated", logEvent: "TICKET_DETAILS_UPDATE", plural: true},
    // Due Date
    TICKET_DUE_DATE_UPDATE: {target: "due date", action: "updated", logEvent: "TICKET_DUE_DATE_UPDATE"},
    // Follower
    TICKET_FOLLOWER_ADD: {target: "follower", action: "added", logEvent: "TICKET_FOLLOWER_ADD"},
    TICKET_FOLLOWER_DELETE: {target: "follower", action: "deleted", logEvent: "TICKET_FOLLOWER_DELETE"},
    // Header
    TICKET_HEADER_AWAKE: {action: "awakened", logEvent: "TICKET_HEADER_AWAKE"},
    TICKET_HEADER_CANCEL: {action: "canceled", logEvent: "TICKET_HEADER_CANCEL"},
    TICKET_HEADER_COMPLETE: {action: "completed", logEvent: "TICKET_HEADER_COMPLETE"},
    TICKET_HEADER_REOPEN: {logEvent: "TICKET_HEADER_REOPEN"},
    TICKET_HEADER_SNOOZE: {action: "snoozed", logEvent: "TICKET_HEADER_SNOOZE"},
    TICKET_HEADER_START: {action: "started", logEvent: "TICKET_HEADER_START"},
    TICKET_HEADER_UNPAIR: {action: "unpaired", logEvent: "TICKET_HEADER_UNPAIR"},
    TICKET_TITLE_UPDATE: {target: "title", action: "updated", logEvent: "TICKET_TITLE_UPDATE"},
    TICKET_VISIBILITY_UPDATE: {target: "visibility", action: "updated", logEvent: "TICKET_VISIBILITY_UPDATE"},
    // History
    TICKET_HISTORY_GET: {target: "history", action: "found", logEvent: "TICKET_HISTORY_GET"},
    TICKET_HISTORY_LOAD_TAGGED_USERS: {target: "tagged users", action: "found", logEvent: "TICKET_HISTORY_LOAD_TAGGED_USERS"},
    // Info
    TICKET_INFO_UPDATE: {logEvent: "TICKET_INFO_UPDATE"},
    // Owner
    TICKET_OWNER_GET: {target: "owner", action: "found", logEvent: "TICKET_OWNER_GET"},
    // Reassign
    TICKET_REASSIGN_ADD: {action: "reassigned", logEvent: "TICKET_REASSIGN_ADD"},
    // Reminder
    TICKET_REMINDER_ADD: {target: "reminder", action: "added", logEvent: "TICKET_REMINDER_ADD"},
    TICKET_REMINDER_DELETE: {target: "reminder", action: "deleted", logEvent: "TICKET_REMINDER_DELETE"},
    TICKET_REMINDER_GET: {target: "reminder", action: "found", logEvent: "TICKET_REMINDER_GET"},
    // Severity
    TICKET_SEVERITY_UPDATE: {target: "severity", action: "updated", logEvent: "TICKET_SEVERITY_UPDATE"},
    // Times
    TICKET_TIME_ADD: {target: "time", action: "added", logEvent: "TICKET_TIME_ADD"},
    TICKET_TIME_GET: {target: "time", action: "found", logEvent: "TICKET_TIME_GET"},
    // Passcodes
    TICKET_PASSCODE_ADD: {target: "passcode", action: "added", logEvent: "TICKET_PASSCODE_ADD"},
    TICKET_PASSCODE_DELETE: {target: "passcode", action: "deleted", logEvent: "TICKET_PASSCODE_DELETE"},
    TICKET_PASSCODE_UPDATE: {target: "passcode", action: "updated", logEvent: "TICKET_PASSCODE_UPDATE"},
    TICKET_PASSCODE_REVEAL: {target: "passcode", action: "found", logEvent: "TICKET_PASSCODE_REVEAL"},
    TICKET_DEVICES_AND_PASSCODES_GET: {target: "passcode", action: "found", logEvent: "TICKET_DEVICES_AND_PASSCODES_GET"},

    /* Ticket Search */
    FILTER_DATE_ADD: {target: "filter", action: "added"},
};

export enum HistoryUpdatedFieldEnum {
    ASSIGNED_TO = "assignedTo",
    ASSIGNED_TO_ID = "assignedToID",
    COMPLETED_DATE = "completedDate",
    COMPLETION_DATE = "completionDate",
    REASON_FOR_ASSIGNMENT = "reasonForAssignment",
    SEVERITY = "severity",
    START_DATE = "startDate",
    VISIBILITY = "visibility",
    VOID = "void",
}

export const STATUS_MAP = new Map<TicketStatusCodeEnum, string>([
    [0, "Unassigned"],
    [1, "Assigned"],
    [2, "Accepted"],
    [3, "Awaiting Quote"],
    [4, "Resolution Pending"],
    [9, "Resolved - Completed"],
    [10, "Resolved - Duplicate"],
    [11, "Resolved - Dismissed"],
    [12, "Closed Automatically - System expired"],
]);

export enum TicketInclude {
    COMMENTS = "comments",
    FOLLOWERS = "followers",
    ATTACHMENTS = "attachments",
    SALESFORCE_PARENT = "sf_parent",
}

export const enum TicketStatusCodeEnum {
    UNASSIGNED = 0,
    ASSIGNED = 1,
    ACCEPTED = 2,
    AWAITING_QUOTE = 3,
    RESOLUTION_PENDING = 4,
    RESOLVED_COMPLETED = 9,
    RESOLVED_DUPLICATE = 10,
    RESOLVED_DISMISSED = 11,
    CLOSED_AUTOMATICALLY = 12,
}

export const enum TicketListDefaultEnum {
    SEVERITY = "No severity level",
    ASSIGNED_TO = "No user assigned",
    COMMENTS = "No comments",
    COMPLETED_AT = "Not completed",
    TICKETS = "No tickets found",
    CASE_NUMBER = "No case number",
    RESERVATION_ID = "No reservation ID",
    CREATED_BY = "No author",
    COMPLETION_DATE = "No date available",
}

export const enum ListFilterEnum {
    MANAGER_ID = "manager_id",
    ASSIGNED_TO = "assigned_to",
    FOLLOWER_ID = "follower_id",
    UNIT_ID = "unit_id",
    TITLE = "title",
    STATUS = "status",
    STATUS_LTE = "status_lte",
    COMPLETION_DATE_LTE = "completion_date_lte",
    START_DATE = "creation_date_gte",
    END_DATE = "creation_date_lte",
    OVERDUE = "overdue",
    SEVERITY = "severity",
    CREATED_BY = "created_by",
    VISIBILITY = "visibility",
}

// General list of dispositions
export const enum DispositionsEnum {
    OWNER_CLEAN = 268,
    CANCELLED_RESERVATION = 269,
    UNDERFUNDED_RESERVATION = 270,
    FORCED_MOVE = 271,
    RESERVATION_FEES = 272,
    GUEST_DAMAGE = 273,
    MANAGEMENT_FEE = 276,
    INTEGRATION_WELCOME_CREDITS = 277,
    APP_CLAIM = 285,
}

export const enum SearchOrderKeysEnum {
    VISIBILITY = "visibility",
    TITLE = "title",
    UNIT_ID = "unit_id",
    CREATION_DATE = "creation_date",
    STATUS = "status",
    UPDATED_AT = "updated_at",
    TICKET_ID = "TicketID",
    COMMENTS_COUNT = "comments_count",
    UNIT_CODE = "unit_code",
    COMPLETED_DATE = "completed_date",
    COMPLETION_DATE = "completion_date",
    RESERVATION_ID = "reservation_id",
    SEVERITY = "severity",
    DUE_DATE = "completion_date",
}

export const enum TaggedUsernameRegex {
    PATTERN = "[^\\s<>,\n]",
    AT_INCLUDED = `(@${PATTERN}+)`,
    AT_EXCLUDED = `@(${PATTERN}+)`,
}

export const OwnerSummaryTopicMapping = {
    rates: "Revenue Performance",
    guests: "Guest Issues",
    administrative: "Account Management",
    termination: "Termination Risk",
};

export const OPEN_STATUS: FilterOption = {
    name: "Open",
    value: [
        TicketStatusCodeEnum.UNASSIGNED,
        TicketStatusCodeEnum.ASSIGNED,
        TicketStatusCodeEnum.ACCEPTED,
        TicketStatusCodeEnum.AWAITING_QUOTE,
        TicketStatusCodeEnum.RESOLUTION_PENDING,
    ],
};
export const CLOSE_STATUS: FilterOption = {
    name: "Closed",
    value: [
        TicketStatusCodeEnum.RESOLVED_COMPLETED,
        TicketStatusCodeEnum.RESOLVED_DUPLICATE,
        TicketStatusCodeEnum.RESOLVED_DISMISSED,
        TicketStatusCodeEnum.CLOSED_AUTOMATICALLY,
    ],
};

export const DEFAULT_FILTER_VALUES: SearchSelectedFilterValues = {
    [ListFilterEnum.STATUS]: [OPEN_STATUS],
    [ListFilterEnum.START_DATE]: {
        value: moment().subtract(2, "weeks").format("YYYY-MM-DD"),
        name: ListFilterEnum.START_DATE,
    },
    [ListFilterEnum.END_DATE]: {
        value: moment().format("YYYY-MM-DD"),
        name: ListFilterEnum.END_DATE,
    },
};

export const DEFAULT_SEARCH_PAGINATION_VALUES = {
    NUMBER: 1,
    LIMIT: 10,
};

export const DEFAULT_SLIM_FILTER_VALUES: SearchSelectedFilterValues = {
    [ListFilterEnum.START_DATE]: {
        value: moment().subtract(2, "weeks").format("YYYY-MM-DD"),
        name: ListFilterEnum.START_DATE,
    },
    [ListFilterEnum.END_DATE]: {
        value: moment().format("YYYY-MM-DD"),
        name: ListFilterEnum.END_DATE,
    },
};

export const enum SortOrderEnum {
    ASCENDING = "asc",
    DESCENDING = "desc",
}

export const DEFAULT_SORTING_VALUES: SearchOrderByQueryParams = {
    [SearchOrderKeysEnum.SEVERITY]: SortOrderEnum.ASCENDING,
};
export const enum ActionLinkEnum {
    ALL_TICKETS_BY_UNIT,
    ADD_TICKET,
    SEARCH_TICKET,
}

export const ACTION_LINK_MAP = new Map<ActionLinkEnum, string>([
    [ActionLinkEnum.ALL_TICKETS_BY_UNIT, "%s/admin/dashboard/tickets/units?UnitID=%s"],
    [ActionLinkEnum.ADD_TICKET, "%s/admin/dashboard/tickets/edit?Maintenance=1&UnitID=%s"],
    [ActionLinkEnum.SEARCH_TICKET, "%s/admin/dashboard/tickets/search?filter=%s"],
]);

export const enum TableSearchColEnum {
    SEVERITY,
    VISIBLE,
    TITLE,
    UNIT_CODE,
    RESERVATION_ID,
    STATUS,
    CREATED_AT,
    DUE_DATE,
    COMMENT_COUNT,
    ASSIGNED_TO,
    CREATED_BY,
    OWNER_CREATED,
    CASE_NUMBER,
    TICKET_ID,
    LAST_COMMENT,
}

export const enum TableSearchVariantEnum {
    HEADER,
    ROW,
    DEFAULT,
}

export const IFRAME_QUERY_PARAM = "iframe";
export const IFRAME_QUERY_PARAM_REDIRECT = "redirect";
