import React, {useEffect} from "react";
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { RootState, useAppDispatch } from "store/store";
import { useSelector} from "react-redux";
import { fetchUnit } from "store/ticketEditUnitSlice";
import { AdminService } from "services/AdminService";
import { ConnectUnitManager } from "typing/dto"
import isEmpty from "lodash/isEmpty";

interface ITicketEditUnitProps {
    unitId?: number;
}

export const TicketEditUnit: React.FC<ITicketEditUnitProps> = ({unitId}) => {
    const dispatch = useAppDispatch();
    const unit = useSelector((state: RootState) => state.ticketEditUnit.unit);
    const connectUnit = useSelector((state: RootState) => state.ticketEditUnit.connectUnit);
    const isLoading = useSelector((state: RootState) => state.ticketEditUnit.isLoading);

    useEffect(() => {
        if (!unitId) return;
        dispatch(fetchUnit(unitId))
      }, [dispatch, unitId]);

    // Loading
    if (isLoading) return <TicketBoxCollapsable header={<h2 data-testid="ticket-edit-unit-is-loading">Unit</h2>}>Loading...</TicketBoxCollapsable>

    // Early return if no unit
    if (!unitId) return <TicketBoxCollapsable header={<h2 data-testid="ticket-edit-unit-not-found">Unit</h2>}>Not found</TicketBoxCollapsable>

    // Loading
    if (isLoading || !unit) return <TicketBoxCollapsable header={<h2>Unit</h2>}>Loading...</TicketBoxCollapsable>
    
    const Header = (
        <div data-testid="ticket-edit-unit-header">
            <h2>Unit</h2>
            <a href={AdminService.getUnitTicketsUrl(unit.legacy_unit_id)} target="_blank" rel="noreferrer">{unit?.unit_code}</a>
        </div>
    );

    const DisplayManager = ({manager}: {manager: ConnectUnitManager | undefined}) => (
        <>
            {
                !isEmpty(manager) ? (
                    <a href={AdminService.getUserTicketsUrl(manager.id)} target="_blank" rel="noreferrer">{manager.first_name} {manager.last_name}</a>
                ) : (
                    <>Not found</>
                )
            }
        </>
    )
    
    return ( 
        <TicketBoxCollapsable header={Header}>
            <div>
                <b>ID:</b> <a href={AdminService.getUnitUrl(unit.legacy_unit_id)} target="_blank" rel="noreferrer">{unit.legacy_unit_id}</a><br/>
                <b>Name:</b> {unit.name}<br/>
                <b>Status:</b> {unit.active_status === "active" ? "Active": "Inactive"}; {unit.display ? "Display": "Do Not Display"}<br/>
                <b>Local Operations Manager: </b><DisplayManager manager={connectUnit?.local_operations_manager}/><br/>
                <b>Operations Manager: </b><DisplayManager manager={connectUnit?.general_manager}/><br/>
            </div>
        </TicketBoxCollapsable>
    );
}
