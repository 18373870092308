// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // Implicit Flow Configuration
    REACT_APP_SPA_URL: "https://ticketing.platform-tools.vacasastage.services",

    REACT_APP_SPA_IDP_AUDIENCE: [
        "ticketing.vacasastage.io",
        "stage.units.vacasa.io",
        "connect.vacasait.com",
        "api.smart-home.vacasastage.io",
        "reservation.vacasastage.io",
        "genai.data-science.vacasastage.com",
    ].join(" "),

    REACT_APP_SPA_IDP_SCOPES: [
        "maintenance-tickets:read",
        "maintenance-tickets:write",
        "tickets:read",
        "tickets:write",
        "units:read",
        "users:read",
        "smart-home:read",
        "smart-home:write",
        "reservations:read",
        "owner-summary:read",
    ].join(" "),

    // IDP Vars
    REACT_APP_IDP_URL: "https://stage.accounts.vacasa.io",
    REACT_APP_IDP_REDIRECT_URI: "https://ticketing.platform-tools.vacasastage.services",
    REACT_APP_IDP_CLIENT_ID: "jUSljZ9FmqciokEe8hQTusCJ1kbOzgwjYRVTXB8V",

    // Datadog
    REACT_APP_DD_URL: "datadoghq.com",
    REACT_APP_DATADOG_TOKEN: "pubd8574179531d421033334cd5dbf819ed",

    // Services
    REACT_APP_GENAI_SERVICE_URL: "https://genai.data-science.vacasastage.com/api/v1",
    REACT_APP_CONNECT_SERVICE_URL: "https://connect.vacasait.com/v1",
    REACT_APP_TICKETING_SERVICE_URL: "https://ticketing.vacasastage.io/v1",
    REACT_APP_UNITS_SERVICE_URL: "https://stage.units.vacasa.io/api/v2",
    REACT_APP_ADMIN_URL: "https://admin.vacasastage.com",
    REACT_APP_SMART_HOME_SERVICE_URL: "https://api.smart-home.vacasastage.io",
    REACT_APP_RESERVATION_SERVICE_URL: "https://reservation.vacasastage.io",

    // Salesforce
    REACT_APP_SALESFORCE_URL: "https://vacasa--uat.sandbox.lightning.force.com",

    // Config files
    TICKETING_CONFIG_FILE: "https://cdn.ticketing.vacasastage.services/config.json",
};
