import React, { useMemo } from "react";
import styles from "./TicketSearchTable.module.scss";
import { LIST_DATE_FORMAT, ListFilterEnum, SearchOrderKeysEnum, TableSearchColEnum, TableSearchVariantEnum, TicketListDefaultEnum } from "appConstants";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableCellProps } from "@material-ui/core";
import { TableSortLabel } from 'components/table-sort-label/TableSortLabel';
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import useURLParams from "hooks/useURLParams";
import Moment from "react-moment";
import { setPage, setReload } from "store/ticketSearchSlice";
import type { RootState } from "store/store";
import type { BaseSyntheticEvent } from "react";
import type { MetaPagination } from "typing/request";
import type { UserConnectAttributes, UnitV2Attributes } from "typing/dto";
import type { TicketSearchRow } from "typing";
import {TicketingService} from "services/TicketingService";
import { goToReservation, goToTicket } from "utils/helpers";
import { TicketSearchCommentTooltip } from "components/ticket-search-comment-tooltip/TicketSearchCommentTooltip";
import { AdminService } from "services/AdminService";
import { ExtraTextTooltip } from "components/extra-text-tooltip/ExtraTextTooltip";
import classNames from "classnames";

interface ITicketSearchTableProps {
}
export const TicketSearchTable: React.FC<ITicketSearchTableProps> = () => {
    const ticketingService = TicketingService.getInstance();
    const dispatch = useAppDispatch();
    const { setURLParams } = useURLParams();
    const visibilityMap = new Map<string, string>([
        ["owner", "Staff & Owner"],
        ["internal", "Staff Only"]
    ]);

    const ticketList = useSelector(
        (state: RootState) => state.ticketSearch.tickets
    );
    const pagination: MetaPagination | null = useSelector(
        (state: RootState) => state.ticketSearch.pagination
    );
    const units: UnitV2Attributes[] = useSelector(
        (state: RootState) => state.ticketSearch.units
    );

    const severityMap = useMemo(() => {
        const config = ticketingService.getConfig(ListFilterEnum.SEVERITY) || [];
        return new Map(config.map((severity) => [severity.value, severity.text]));
    }, [ticketingService]);

    const users: UserConnectAttributes[] = useSelector(
        (state: RootState) => state.ticketSearch.users
    );

    const rows: TicketSearchRow[] = ticketList ?
        ticketList.map(ticket => {
            const {attributes, included, includedMap} = ticket;
            const lastComment = included?.length && includedMap?.comments ? includedMap.comments[0] : null;
            return {
                id: ticket.id,
                visible: visibilityMap.get(attributes.visibility),
                title: attributes.title,
                unitCode: attributes.unit_id,
                status: attributes.display_status,
                createdAt: attributes.creation_date,
                completion_date: attributes.completion_date,
                commentCount: attributes.comments_count,
                assignedTo: attributes.assigned_to,
                createdBy: attributes.created_by,
                ownerCreated: attributes.requested_by === 1 ? 'Y':'N',
                caseId: attributes.sf_case_id,
                caseNumber: attributes.sf_case_number,
                lastComment: lastComment?.attributes,
                severity: severityMap.get(attributes.severity),
                reservationId: attributes.reservation_id,
            } as unknown as TicketSearchRow;
        }): [];

    const rowsPerPageOptions = [10, 25, 100];
    const page = pagination?.current_page ? pagination?.current_page - 1 : 0;
    const rowsPerPage = pagination?.per_page ? Number(pagination.per_page) : 20;
    const count = pagination?.total ?? 0;

    const handleChangePage = (_event: any, page: number) => {
        const currentPage = page + 1;
        setURLParams({'page[limit]': String(pagination?.per_page || 10)});
        setURLParams({'page[number]': String(currentPage)});
        dispatch(setPage({
            limit: Number(pagination?.per_page),
            number: page + 1
        }));
        dispatch(setReload());
    };
    
    const handleChangeRowsPerPage = (event: BaseSyntheticEvent) => {
        const pageLimit: string = event.target.value;
        setURLParams({'page[limit]': pageLimit})
        dispatch(setPage({
            limit: pageLimit,
            number: pagination?.current_page
        }));
        dispatch(setReload());
    };

    return ( 
        <section className={styles.content}>
            <TableContainer>
                <Table data-testid="ticket-search-table">
                    <TableHead data-testid="ticket-search-table-header">
                        <TicketSearchTableRow variant={TableSearchVariantEnum.HEADER} />
                    </TableHead>
                    <TableBody data-testid="ticket-search-table-body">
                        {rows.length ? (rows.map((row: any) => (
                            <TicketSearchTableRow variant={TableSearchVariantEnum.ROW} users={users} units={units} row={row} />
                        ))) : 
                            <TicketSearchTableRow variant={TableSearchVariantEnum.DEFAULT} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </section>
    );
}
interface ITicketSearchTableCellProps extends TableCellProps {
    visible?: boolean,
    children: React.ReactNode,
}
export const TicketSearchTableCell: React.FC<ITicketSearchTableCellProps> = ({children, visible= true, ...rest}:ITicketSearchTableCellProps) => {
    return (
        <>
            { visible &&
                <TableCell {...rest}>
                    {children}
                </TableCell>
            }
        </>
    )
};

interface ITicketSearchTableRowProps {
    variant?: TableSearchVariantEnum,
    row?: TicketSearchRow,
    users?: UserConnectAttributes[],
    units?: UnitV2Attributes[],
}
export const TicketSearchTableRow: React.FC<ITicketSearchTableRowProps> = ({row, users = [], variant = TableSearchVariantEnum.ROW, units = []}:ITicketSearchTableRowProps) => {
    const isHeader = variant === TableSearchVariantEnum.HEADER;
    const isRow = variant === TableSearchVariantEnum.ROW;
    const isDefault = variant === TableSearchVariantEnum.DEFAULT;

    const titleMaxLength = 40;
    const usernameMaxLength = 20;

    const cellWidthMap = new Map<TableSearchColEnum, string>([
        [TableSearchColEnum.SEVERITY, "6%"],
        [TableSearchColEnum.VISIBLE, "5%"],
        [TableSearchColEnum.TITLE, "11%"],
        [TableSearchColEnum.UNIT_CODE, "7%"],
        [TableSearchColEnum.RESERVATION_ID, "7%"],
        [TableSearchColEnum.STATUS, "8%"],
        [TableSearchColEnum.CREATED_AT, "8%"],
        [TableSearchColEnum.DUE_DATE, "8%"],
        [TableSearchColEnum.COMMENT_COUNT, "6%"],
        [TableSearchColEnum.ASSIGNED_TO, "7%"],
        [TableSearchColEnum.CREATED_BY, "7%"],
        [TableSearchColEnum.OWNER_CREATED, "4%"],
        [TableSearchColEnum.CASE_NUMBER, "6%"],
        [TableSearchColEnum.TICKET_ID, "6%"],
        [TableSearchColEnum.LAST_COMMENT, "12%"],
    ]);

    const getUserName = (userId: number) =>  users
        .filter((user:UserConnectAttributes) => Number(user.id) === Number(userId))
        .map((user:UserConnectAttributes) => `${user.first_name} ${user.last_name}`)
        .join();

    const getUnitCode = (unitId: number) => units
        .filter((unit:UnitV2Attributes) => Number(unit.legacy_unit_id) === Number(unitId))
        .map((unit:UnitV2Attributes) => `${unit.unit_code}`)
        .join();

    const author = getUserName(Number(row?.createdBy));
    const assignee = getUserName(Number(row?.assignedTo));
    const commentAuthor = getUserName(row?.lastComment?.user_id || 0);
    const unitCode = getUnitCode(Number(row?.unitCode));
    return (
    <>
        {isHeader &&
            <TableRow key="header">
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.SEVERITY) }} align="left">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.SEVERITY}>Severity</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.VISIBLE) }} align="center">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.VISIBILITY}>Visible</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.TITLE) }} align="left">Title</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.UNIT_CODE) }} align="left">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.UNIT_CODE}>Unit Code</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.RESERVATION_ID) }} align="left">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.RESERVATION_ID}>Reservation</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.STATUS) }} align="left">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.STATUS}>Status</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.CREATED_AT), position: 'relative'}} align="left">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.CREATION_DATE}>Created at</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.DUE_DATE), position: 'relative'}} align="left">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.DUE_DATE}>Due date</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.COMMENT_COUNT) }} align="left">
                    <TableSortLabel sortProperty={SearchOrderKeysEnum.COMMENTS_COUNT}>Comment Count</TableSortLabel>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.ASSIGNED_TO) }} align="center">Assigned To</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.CREATED_BY) }} align="left">Created By</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.OWNER_CREATED) }} align="center">Owner Created</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.CASE_NUMBER) }} align="left">Case #</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.TICKET_ID) }} align="center">Ticket ID</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.LAST_COMMENT) }}>Last comment</TicketSearchTableCell>
            </TableRow>
        }
        {isRow && row && 
            <TableRow key={row.id} onClick={() => {goToTicket(row)}}>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.SEVERITY) }} align="center">{row.severity || TicketListDefaultEnum.SEVERITY}</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.VISIBLE) }} align="center">{row.visible}</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.TITLE) }}>
                    <ExtraTextTooltip text={row.title} maxLength={titleMaxLength}/>
                </TicketSearchTableCell>
                <TicketSearchTableCell className="line-break" style={{ width: cellWidthMap.get(TableSearchColEnum.UNIT_CODE) }}>{unitCode}</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.RESERVATION_ID) }} onClick={(e) => row.reservationId && goToReservation(row, e)} className={classNames(styles.link)} role="cell">
                    { row.reservationId 
                        ? <a className="line-break"
                            href={AdminService.getReservationUrl(row.reservationId)}
                            target="_blank"
                            data-testid="reservation-id"
                            rel="noreferrer">{row.reservationId}
                        </a>
                        : TicketListDefaultEnum.RESERVATION_ID
                    }
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.STATUS) }}>{row.status}</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.CREATED_AT) }}>
                    <Moment className="uppercase" format={LIST_DATE_FORMAT}>{row.createdAt}</Moment>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.DUE_DATE) }}>
                    {
                        row.completion_date ? (
                        <Moment className="uppercase" format={LIST_DATE_FORMAT}>
                            {row.completion_date}
                        </Moment>
                        ) : TicketListDefaultEnum.COMPLETION_DATE
                    }
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.COMMENT_COUNT) }} align="center">{row.commentCount}</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.ASSIGNED_TO) }}>
                    <ExtraTextTooltip text={assignee} maxLength={usernameMaxLength}/>
                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.CREATED_BY) }}>
                    {
                        author
                        ? <ExtraTextTooltip text={author} maxLength={usernameMaxLength}/>
                        : TicketListDefaultEnum.CREATED_BY
                    }

                </TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.OWNER_CREATED) }} align="center">{row.ownerCreated}</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.CASE_NUMBER) }}>
                    {
                        row.caseNumber
                        ? <span className="line-break">{row.caseNumber}</span>  
                        : TicketListDefaultEnum.CASE_NUMBER
                    }
                </TicketSearchTableCell>
                <TicketSearchTableCell className="line-break" style={{ width: cellWidthMap.get(TableSearchColEnum.TICKET_ID) }} align="center">{row.id}</TicketSearchTableCell>
                <TicketSearchTableCell style={{ width: cellWidthMap.get(TableSearchColEnum.LAST_COMMENT) }}>
                    {
                        row.lastComment 
                        ? <TicketSearchCommentTooltip comment={row.lastComment} username={commentAuthor} />
                        : TicketListDefaultEnum.COMMENTS
                    }
                </TicketSearchTableCell>
            </TableRow>
        }
        {isDefault && 
            <TableRow>
                <TableCell style={{ width: '100%' }} colSpan={cellWidthMap.keys.length} align="center">
                    {TicketListDefaultEnum.TICKETS}
                </TableCell>
            </TableRow>
        }
    </>)
};
