import { Spinner } from "components/spinner/Spinner";
import { ReactNode, useEffect, useState } from "react";
import { ConnectService } from "services/ConnectService";
import TicketingService from "services/TicketingService";

interface IConfigProviderProps {
    children: ReactNode;
}

const ConfigProvider: React.FC<IConfigProviderProps> = ({ children }) => {

    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const initializeConfig = async () => {
          const connectService = ConnectService.getInstance();
          const ticketingService = TicketingService.getInstance();

          await Promise.all([
            connectService.setCurrentUser(),
            ticketingService.loadConfig(),
          ]);

          setIsFetching(false);
        };
        initializeConfig();
      }, []);

    return (isFetching ? <Spinner data-testid="spinner" fullscreen /> : children)
}

export default ConfigProvider;
