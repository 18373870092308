import React, { BaseSyntheticEvent, useState } from "react";
import styles from "./ActionBar.module.scss";
import classNames from "classnames";
import { env } from "core/env";
import { ActionLinkEnum, ACTION_LINK_MAP } from "appConstants";
import { TextField } from "@material-ui/core";
import { ReactComponent as IconSearch } from "assets/icons/search.svg"

interface IActionBarProps {
    unitId?: string,
    allowedButtons?: Partial<Record<ActionLinkEnum, boolean>>
}

export const ActionBar: React.FC<IActionBarProps> = ({ unitId, allowedButtons }:IActionBarProps) => {
    const [text, setText] = useState<string>("");
    const legacyHost = env.REACT_APP_ADMIN_URL;
    const searchPlaceholder = "Search";
    
    const goTo = (actionLink: ActionLinkEnum, values: string[]) => {
        const template = ACTION_LINK_MAP?.get(actionLink);
        if (!template) return;
        let i = 0;
        const url = template.replace(/%s/g, () => values[i++] || "")
        window.open(url, '_blank');
    };

    const onChange = (event: BaseSyntheticEvent) => {
        setText(event.target.value);
    };
    
    return ( 
        <div data-testid="action-bar" className={styles.content}>
            <aside>
                { allowedButtons?.[ActionLinkEnum.ALL_TICKETS_BY_UNIT] &&
                    <button 
                        data-testid="action-bar-all-tickets-btn"
                        className={classNames(styles['button'], "button")}
                        onClick={() => goTo(ActionLinkEnum.ALL_TICKETS_BY_UNIT, [legacyHost, unitId])}
                    >
                        All tickets
                    </button>
                }
                { allowedButtons?.[ActionLinkEnum.ADD_TICKET] &&
                    <button
                        data-testid="action-bar-add-ticket-btn"
                        className={classNames(styles['button'], "button")}
                        onClick={() => goTo(ActionLinkEnum.ADD_TICKET, [legacyHost, unitId])}
                    >
                        Add ticket
                    </button>
                }
                { allowedButtons?.[ActionLinkEnum.SEARCH_TICKET] &&
                    <div>
                        <TextField
                            type="text"
                            placeholder={searchPlaceholder}
                            value={text}
                            onChange={onChange}
                            variant="outlined"
                            data-testid="action-bar-search-ticket-text"
                        />
                        <button
                            data-testid="action-bar-search-ticket-btn"
                            className="btn-chip"
                            onClick={() => goTo(ActionLinkEnum.SEARCH_TICKET, [legacyHost, text])}
                        >
                            <IconSearch className="icon-bg" />
                        </button>
                    </div>
                }
            </aside>
        </div>
    );
}
