import { ListFilterEnum, TicketStatusCodeEnum, DEFAULT_SEARCH_PAGINATION_VALUES } from "appConstants";
import Toggle from "components/toggle/Toggle";
import useURLParams from "hooks/useURLParams";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { setPage, setReload, setSelectedFilterValues } from "store/ticketSearchSlice";
import styles from "./OverdueFilter.module.scss";
import type { SearchSelectedFilterValues } from "typing/request";
import { resetURLPagination } from "utils/url-params"

interface IOverdueFilterProps {
    title?: string;
}

export const OverdueFilter = ({ title }: IOverdueFilterProps) => {
    const dispatch = useAppDispatch();
    const { setURLParams } = useURLParams();
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );

    const handleOverdue = async (isChecked: boolean) => {
        const completion_date_lte_value = isChecked
            ? { id: moment().format("YYYY-MM-DD"), name: ListFilterEnum.COMPLETION_DATE_LTE }
            : {};
        const status_lte_value = isChecked
            ? { id: String(TicketStatusCodeEnum.RESOLVED_COMPLETED), name: ListFilterEnum.STATUS_LTE }
            : {};

        const isOverdueActive = status_lte_value.id && completion_date_lte_value.id;
        setURLParams({"filter[overdue]": isOverdueActive ? "true" : "false"});
        resetURLPagination(setURLParams);
        dispatch(setPage({
            limit: DEFAULT_SEARCH_PAGINATION_VALUES.LIMIT,
            number: DEFAULT_SEARCH_PAGINATION_VALUES.NUMBER
        }));

        dispatch(setSelectedFilterValues({
            ...selectedFilterValues,
            [ListFilterEnum.COMPLETION_DATE_LTE]: completion_date_lte_value,
            [ListFilterEnum.STATUS_LTE]: status_lte_value
        }));

        // Reloads the page
        dispatch(setReload());
    }

    const isOverdueFilterActive = !!(selectedFilterValues[ListFilterEnum.STATUS_LTE] && selectedFilterValues[ListFilterEnum.COMPLETION_DATE_LTE]);

    return (
        <div className={styles.content}>
            {title && <h4 data-testid="text-input-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <Toggle
                isToggled={isOverdueFilterActive}
                onToggle={handleOverdue}
            />
        </div>
    )
}
