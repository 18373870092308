import React, { createContext, useContext } from 'react';
import { useSearchParams, URLSearchParamsInit } from 'react-router-dom';

export type SetSearchParams = (
    nextInit: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOptions?: { replace?: boolean; state?: any }
) => void;

// Context interface
interface SearchParamsContextType {
    searchParams: URLSearchParams;
    setSearchParams: SetSearchParams;
}

// Context definition
const SearchParamsContext = createContext<SearchParamsContextType | undefined>(undefined);

// Context provider
export const SearchParamsProvider = ({ children }: { children: React.ReactNode }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <SearchParamsContext.Provider value={{ searchParams, setSearchParams }}>
            {children}
        </SearchParamsContext.Provider>
    );
};

// Hook to use the contexts safety
export const useSharedSearchParams = () => {
    const context = useContext(SearchParamsContext);
    if (!context) {
        throw new Error('useSharedSearchParams most be used inside SearchParamsProvider scope.');
    }
    return context;
};
