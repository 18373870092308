import {ConnectService} from "./ConnectService";
import {ImplicitFlowService} from "./ImplicitFlowService";
import {TICKET_ADD_TIMES_IS_MAINTENANCE, TICKET_FETCH_REMINDER_IS_MAINTENANCE, TICKET_FETCH_REMINDER_UPCOMING, TicketInclude} from "appConstants";
import {env} from "core/env";
import isEmpty from "lodash/isEmpty";
import qs from "qs";
import type {
    AssignableUserAttributes,
    DispositionV1Attributes,
    OwnerV1Attributes,
    TicketAttachmentsV1Attributes,
    TicketCommentV1Attributes,
    TicketFollowerV1Attributes,
    TicketHistoryV1Attributes,
    TicketReminderV1Attributes,
    TicketTimesV1Attributes,
    TicketV1Attributes,
    TicketV1IncludedMap,
} from "typing/dto";
import type {
    ConfigOption,
    ConfigOptions,
    CustomRequestConfiguration,
    DataType,
    GetListResponse,
    GetResponse,
    GetTicketsResponse,
    PageQueryParams,
    PostAndPatchRequest,
    PostAndPatchResponse,
    QueryParams,
    TicketingServiceFilter,
} from "typing/request";

interface cacheType {
    dispositions: Array<DataType<DispositionV1Attributes>>;
}

/**
 * Ticketing Service V1
 */
export class TicketingService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_TICKETING_SERVICE_URL;
    private static service: TicketingService;
    private connectService: ConnectService;
    private config: ConfigOptions | undefined;

    // in-memory cache
    private cache: cacheType = {
        dispositions: [],
    };

    constructor(connectService: ConnectService) {
        super(TicketingService.baseUrl);
        this.connectService = connectService;
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): TicketingService {
        if (!TicketingService.service) {
            const connectService = ConnectService.getInstance();
            TicketingService.service = new TicketingService(connectService);
        }
        return TicketingService.service;
    }

    /**
     * Get Ticket Times by Ticket ID
     * @returns Not defined yet
     */
    public async getTimes(ticketId: number, userId: number): Promise<GetResponse<TicketTimesV1Attributes[]>> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.get(`${this.apiUrl}/tickets/${ticketId}/hours`, {
                params: {
                    ticketId,
                    userId,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {arrayFormat: "brackets"});
                },
            });
            response.data = response.data.map((times: any) => {
                times.attributes.id = times.id;
                return times.attributes;
            });
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getTimes", {}, e);
            throw e;
        }
    }

    /**
     * Add Ticket Times by Ticket ID
     * @returns Not defined yet
     */
    public async addTimes(
        ticketId: number,
        userId: number,
        note: string,
        fromTime: string,
        toTime: string,
        external: 0 | 1
    ): Promise<PostAndPatchRequest<TicketTimesV1Attributes>> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.post(`${this.apiUrl}/tickets/${ticketId}/hours`, {
                data: {
                    type: "ticket-hour",
                    attributes: {
                        user_id: userId,
                        from: fromTime,
                        to: toTime,
                        note,
                        is_maintenance: TICKET_ADD_TIMES_IS_MAINTENANCE,
                        external,
                    },
                },
            });
            response.data.attributes.id = response.data.id;
            return response?.data.attributes;
        } catch (e) {
            if (!(e instanceof Error)) throw e;

            this.logger.error("addTimes", {ticketId}, e);
            throw e;
        }
    }

    /**
     * Get Ticket Entity based on it's id
     * @returns
     */
    public async getTicketById(ticketId: number, include?: TicketInclude[]): Promise<GetResponse<TicketV1Attributes, TicketV1IncludedMap>> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.get(`${this.apiUrl}/tickets/${ticketId}`, {
                params: isEmpty(include) ? {} : {include: include?.toString()},
                paramsSerializer: (params) => {
                    return qs.stringify(params, {arrayFormat: "brackets"});
                },
            });
            response.data.attributes.id = response.data.id;
            return response;
        } catch (e) {
            if (!(e instanceof Error)) throw e;

            this.logger.error("getTicketById", {ticketId}, e);
            throw e;
        }
    }

    /**
     * Gets all ticket entities by pagination and allowed filters:
     * @param object QueryParams
     * @param customRequestConfig CustomRequestConfiguration
     */
    public async getTickets(
        {page, filter, sort, include}: QueryParams<TicketingServiceFilter>,
        customRequestConfig: CustomRequestConfiguration = {}
    ): Promise<GetTicketsResponse | void> {
        try {
            const axios = await this.axiosInstance(customRequestConfig);
            const response = await axios.get(`${this.apiUrl}/tickets`, {
                timeout: 35000,
                params: {
                    page,
                    filter,
                    sort,
                    include,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {arrayFormat: "brackets"});
                },
            });
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getTickets", {}, e);
            throw e;
        }
    }

    /**
     * Gets all assignee entities by pagination and allowed filters
     * @param page
     * @param filter
     * @param customRequestConfig
     * @returns
     */
    public async getAssignees(
        filter?: TicketingServiceFilter,
        page?: PageQueryParams,
        customRequestConfig: CustomRequestConfiguration = {}
    ): Promise<GetListResponse<AssignableUserAttributes>> {
        try {
            const axios = await this.axiosInstance(customRequestConfig);
            const response = await axios.get(`${this.apiUrl}/users/assignable`, {
                params: {
                    page,
                    filter,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {arrayFormat: "brackets"});
                },
            });
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getAssignees", {}, e);
            throw e;
        }
    }

    /**
     * Get Ticket Attachments based on it's Id
     * @param ticketId
     * @returns
     */
    public async getAttachments(ticketId: number): Promise<GetResponse<TicketAttachmentsV1Attributes[]>> {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/tickets/${ticketId}/attachments`);
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getAttachments", {}, e);
            throw e;
        }
    }

    /**
     * Delete Ticket Attachments based on it's and the filename
     * @param ticketId
     * @param filename
     * @returns
     */
    public async deleteAttachment(ticketId: number, filename: string): Promise<void> {
        try {
            const axios = await this.axiosInstance();
            await axios.delete(`${this.apiUrl}/tickets/${ticketId}/attachments`, {
                params: {
                    attachmentNames: filename,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {arrayFormat: "brackets"});
                },
            });
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("deleteAttachment", {}, e);
            throw e;
        }
    }

    /**
     * Add Ticket Attachment(s) based on it's Id
     * @param ticketId
     * @param formData
     * @returns
     */
    public async addAttachments(ticketId: number, formData: any): Promise<PostAndPatchResponse<{result: string}>> {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.post(`${this.apiUrl}/tickets/${ticketId}/attachments`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("addAttachments", {}, e);
            throw e;
        }
    }

    /**
     * Patches a ticket
     * @param ticketId
     * @param attributes
     * @returns
     */
    public async patchTicket(ticketId: Number, attributes: Partial<TicketV1Attributes>): Promise<TicketV1Attributes> {
        const request: PostAndPatchRequest<Partial<TicketV1Attributes>> = {
            data: {
                attributes: {
                    ...attributes,
                    modified_by: this.currentUserId,
                },
                type: "ticket",
            },
        };
        try {
            const axios = await this.axiosInstance();
            const response: {data: PostAndPatchResponse<TicketV1Attributes>} = await axios.patch(`${this.apiUrl}/tickets/${ticketId}`, request);
            return response.data.data.attributes;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("patchTicket", {ticketId}, e);
            throw e;
        }
    }

    /**
     * Get Flat Dispositions
     */
    public async getDispositions(): Promise<Array<DataType<DispositionV1Attributes>>> {
        // return from cache if found
        if (this.cache.dispositions.length) {
            return this.cache.dispositions;
        }

        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/dispositions`);
            this.cache.dispositions = response?.data.data;
            return response?.data.data;
        } catch (e) {
            console.log("error", e);
            if (!(e instanceof Error)) throw e;
            this.logger.error("getDispositions", {}, e);
            throw e;
        }
    }

    /**
     * Get Dispositions By id
     * @param dispositionId
     */
    public async getDispositionById(dispositionId: number): Promise<GetResponse<DispositionV1Attributes>> {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/dispositions/${dispositionId}`);
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getDispositionById", {}, e);
            throw e;
        }
    }

    /**
     * Get all ticket reminders
     * @param userId
     * @param ticketId
     */
    public async fetchReminders(userId: number, ticketId: number): Promise<GetListResponse<TicketReminderV1Attributes>> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.get(`${this.apiUrl}/tickets/reminders`, {
                params: {
                    userId,
                    ticketId,
                    isMaintenance: TICKET_FETCH_REMINDER_IS_MAINTENANCE,
                    upcoming: TICKET_FETCH_REMINDER_UPCOMING,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {arrayFormat: "brackets"});
                },
            });
            response.data = response.data.map((reminder: any) => {
                reminder.attributes.id = reminder.id;
                return reminder.attributes;
            });
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("fetchReminders", {}, e);
            throw e;
        }
    }

    /**
     * Add a reminder to a ticket
     * @param ticketId
     * @param user_id
     * @param is_maintenance
     * @param display_date
     * @param message
     */
    public async addReminder({
        ticket_id,
        user_id,
        is_maintenance,
        display_date,
        message,
    }: TicketReminderV1Attributes): Promise<PostAndPatchResponse<TicketReminderV1Attributes>> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.post(`${this.apiUrl}/tickets/reminders`, {
                data: {
                    type: "ticket-reminder",
                    attributes: {
                        ticket_id,
                        user_id,
                        is_maintenance,
                        display_date,
                        message,
                    },
                },
            });
            response.data.attributes.id = response.data.id;
            return response?.data.attributes;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("addReminder", {ticket_id}, e);
            throw e;
        }
    }

    /**
     * Add a follower to a ticket
     * @param ticketId
     * @param followerId
     */
    public async addFollower(ticketId: number, followerId: number): Promise<TicketFollowerV1Attributes> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.post(`${this.apiUrl}/tickets/${ticketId}/followers`, {
                data: {
                    attributes: {
                        user_id: followerId,
                        is_maintenance: true,
                    },
                    type: "ticket-follower",
                },
            });
            response.data.attributes.id = response.data.id;
            return response?.data.attributes;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("addFollower", {ticketId}, e);
            throw e;
        }
    }

    /**
     * Remove a ticket reminder
     * @param reminderId
     */
    public async removeReminder(reminderId: number): Promise<void> {
        try {
            const axios = await this.axiosInstance();
            await axios.delete(`${this.apiUrl}/tickets/reminders/${reminderId}`);
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("removeReminder", {}, e);
            throw e;
        }
    }

    /**
     * Remove a follower to a ticket
     * @param ticketId
     * @param followerId
     */
    public async removeFollower(ticketId: number, followerId: number): Promise<void> {
        try {
            const axios = await this.axiosInstance();
            return await axios.delete(`${this.apiUrl}/tickets/${ticketId}/followers`, {
                data: {
                    data: {
                        attributes: {
                            user_id: followerId,
                        },
                        type: "ticket-follower",
                    },
                },
            });
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("removeFollower", {ticketId}, e);
            throw e;
        }
    }

    /**
     * Adds a comment
     * @param attributes
     */
    public async addComment(
        attributes: Pick<TicketCommentV1Attributes, "external" | "note" | "ticket_id">
    ): Promise<GetResponse<TicketCommentV1Attributes>> {
        const request: PostAndPatchRequest<TicketCommentV1Attributes> = {
            data: {
                attributes: {
                    ...attributes,
                    timestamp: new Date().toISOString(),
                    user_id: this.currentUserId,
                },
                type: "ticket-comment",
            },
        };
        try {
            const axios = await this.axiosInstance();
            const response = await axios.post(`${this.apiUrl}/tickets/comments`, request);
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("addComment", {}, e);
            throw e;
        }
    }

    /**
     * Get history
     * @param filter
     * @param page
     */
    public async getHistory(filter?: TicketingServiceFilter, page?: PageQueryParams): Promise<GetListResponse<TicketHistoryV1Attributes>> {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/tickets/history`, {
                params: {
                    page,
                    filter,
                },
                paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "brackets"}),
            });
            return response?.data;
        } catch (e) {
            if (e instanceof Error) this.logger.error("getHistory", {filter}, e);
            throw e;
        }
    }

    /**
     * Get comments
     * @param filter
     * @param page
     */
    public async getComments(filter?: TicketingServiceFilter, page?: PageQueryParams): Promise<GetListResponse<TicketCommentV1Attributes>> {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/tickets/comments`, {
                params: {
                    page,
                    filter,
                },
                paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "brackets"}),
            });
            return response?.data;
        } catch (e) {
            if (e instanceof Error) this.logger.error("getComments", {filter}, e);
            throw e;
        }
    }

    /**
     * Get owners
     * @param filter
     * @param page
     */
    public async getOwners(filter?: TicketingServiceFilter, page?: PageQueryParams): Promise<GetListResponse<OwnerV1Attributes>> {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.get(`${this.apiUrl}/owners`, {
                params: {
                    page,
                    filter,
                },
                paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "brackets"}),
            });
            return response?.data;
        } catch (e) {
            if (e instanceof Error) this.logger.error("getOwners", {filter}, e);
            throw e;
        }
    }

    get currentUserId(): number {
        return this.connectService.currentUserId;
    }

    public async loadConfig(): Promise<void> {
        if (!this.config) {
            try {
                const response = await fetch(env.TICKETING_CONFIG_FILE);
                this.config = await response.json();
            } catch (error) {
                console.error("Failed to load configuration:", error);
                throw error;
            }
        }
    }

    public getConfig(configName: keyof ConfigOptions): ConfigOption[] | undefined;
    public getConfig(): ConfigOptions;
    public getConfig(configName?: keyof ConfigOptions): ConfigOption[] | ConfigOptions | undefined {
        return configName ? this.config?.[configName] : this.config;
    }

    public setConfig(config: ConfigOptions): void {
        this.config = config;
    }
}

export default TicketingService;
