import React, { useEffect } from 'react';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import { useSelector } from 'react-redux';
import { useAppDispatch } from "store/store";
import { upsertOrderBy, setReload } from "store/ticketSearchSlice";
import type { RootState } from "store/store";
import type { SearchSortOrder, SearchOrderByQueryParams } from "typing/request";
import useURLParams from 'hooks/useURLParams';
import { DEFAULT_SORTING_VALUES, SearchOrderKeysEnum, SortOrderEnum } from 'appConstants';
import styles from "./TableSortLabel.module.scss";

interface ITableSortLabelProps {
    children: React.ReactNode;
    sortProperty: SearchOrderKeysEnum;
    shouldSetQueryParams?: boolean;
}


export const TableSortLabel: React.FC<ITableSortLabelProps> = ({ children, sortProperty, shouldSetQueryParams = true }) => {

    const dispatch = useAppDispatch();
    const { searchParams, upsertSortParam } = useURLParams();
    const orderBy: SearchOrderByQueryParams = useSelector((state: RootState) => state.ticketSearch.orderBy);

    const sortHandler = (property: SearchOrderKeysEnum) => (_event: React.MouseEvent<unknown>) => {
        const currentOrder: SearchSortOrder | undefined = orderBy[property];
        let newOrderBy: { [property: string]: SearchSortOrder | null } | null = null;

        switch (currentOrder) {
            case SortOrderEnum.DESCENDING:
                newOrderBy = { [property]: SortOrderEnum.ASCENDING };
                break;
            case SortOrderEnum.ASCENDING:
                newOrderBy = { [property]: null };
                break;
            default:
                newOrderBy = { [property]: SortOrderEnum.DESCENDING };
                break;
        }

        if (shouldSetQueryParams) upsertSortParam(newOrderBy, false);
        dispatch(upsertOrderBy(newOrderBy));
        dispatch(setReload());
    };

    useEffect(() => {
        const renderWithQueryParams = () => {
            const sortParam = searchParams.get("sort");
            const currentParamValues = sortParam?.split(",") ?? [];
            const currentSortingValue = currentParamValues.find(sortingValue => sortingValue.replace("-", "") === sortProperty)

            if (!currentParamValues || !currentSortingValue) {
                if (sortProperty in DEFAULT_SORTING_VALUES) {
                    const initialValue = {
                        [sortProperty]: (sortParam === null) ? DEFAULT_SORTING_VALUES[sortProperty] : null
                    }
                    dispatch(upsertOrderBy(initialValue));
                }
                return
            }

            const paramOrder = currentSortingValue.startsWith('-') ? SortOrderEnum.DESCENDING : SortOrderEnum.ASCENDING
            dispatch(upsertOrderBy({ [sortProperty]: paramOrder }));
        }
        renderWithQueryParams()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const isActive = Boolean(sortProperty in orderBy && orderBy[sortProperty]);
    const currentDirection = isActive ? orderBy[sortProperty] : SortOrderEnum.DESCENDING;

    return (
        <MuiTableSortLabel
            active={isActive}
            direction={currentDirection}
            onClick={sortHandler(sortProperty)}
            className={styles.content}
        >
            <span data-testid={`sort-${sortProperty}`}>{children}</span>
        </MuiTableSortLabel>
    );
}
