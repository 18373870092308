import styles from "./TicketEditSkeleton.module.scss";
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { TicketBox } from "components/ticket-box/TicketBox";

export const TicketEditSkeleton = () => {
    return (
        <div className={styles.container} data-testid="ticket-edit-skeleton">
            <div className={styles["header-title"]} data-testid="header">
                {/* Title */}
                <Typography component="div" variant="h1">
                    <Skeleton /> 
                </Typography>

                {/* Visibility */}
                <Typography component="div" variant="h3">
                    <Skeleton /> 
                </Typography>
            </div>
            

            <div>
                {/* Title */}
                <br/>
                <Skeleton width={600}/>
                <Skeleton width={600}/>
                <Skeleton width={600}/>
            </div>

            <div className={styles.subheader} data-testid="subheader">
                <TicketBox>
                    <Skeleton className={styles.action}/>
                    <Skeleton className={styles.title}/>
                    <Skeleton className={styles.value}/><br/><br/>
                </TicketBox>
                <TicketBox>
                    <Skeleton className={styles.action}/>
                    <Skeleton className={styles.title}/>
                    <Skeleton />
                </TicketBox>
                <TicketBox>
                    <Skeleton className={styles.action}/>
                    <Skeleton className={styles.title}/>
                    <Skeleton />
                </TicketBox>
            </div>

            <div className={styles.content} data-testid="content">
                <div className={styles.left}>
                    
                    <div className={styles["desc-and-details"]}>
                        <Skeleton height={40} width={150}/>
                        <Skeleton height={40}/>
                    </div>

                    <div className={styles["desc-and-details"]}>
                        <Skeleton height={40} width={150} />
                        <Skeleton height={40} />
                    </div>

                    <div className={styles["desc-and-details"]}>
                        <Skeleton height={40} width={150} />
                        <div className={styles["comment-header"]}>
                            <Skeleton height={30}/>
                            <Skeleton height={30}/>
                            <Skeleton height={30}/>
                        </div>
                        <Skeleton height={160} />
                        <Skeleton width={150} height={40} style={{marginLeft: "auto", marginTop: 20}} />
                    </div>

                    <div>
                        <Skeleton height={40} width={200} />
                        <Skeleton height={100} /><br/>

                        <Skeleton height={40} width={200} />
                        <Skeleton height={40} /><br/>

                        <Skeleton height={40} width={250} /><br/>

                        <Skeleton height={40} width={200} />
                        <Skeleton height={40} /><br/>


                    </div>
                    
                </div>
                
                <div className={styles.right}>

                    { [...Array(5).keys()].map( val =>
                        (
                            <TicketBox key={val}>
                                <Skeleton className={styles.title}/>
                                <Skeleton className={styles.subtitle}/>
                                <Skeleton className={styles.subtitle}/>
                                <Skeleton className={styles.subtitle}/>
                            </TicketBox>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}
