import {FilterOption} from "typing/request";

/**
 * Filters and returns the selected filter options, including individual options
 * and groups where all values are fully selected.
 *
 * @param {string[]} selectedValues - An array of selected values (e.g., IDs or keys).
 * @param {FilterOption[]} options - An array of filter options, where each option can be
 *                                   an individual value or a group of values.
 * @returns {FilterOption[]} - An array of filter options that includes:
 *                             - Individual options that are selected and not part of a fully selected group.
 *                             - Groups where all values are fully selected.
 */
export const getSelectedFilterOptions = (selectedValues: string[], options: FilterOption[]): FilterOption[] => {
    // Filter options that are groups and whose values are fully selected
    const selectedGroups = options.filter((option) => {
        const isArray = Array.isArray(option.value);
        const allValuesSelected = isArray && (option.value as string[]).every((value) => selectedValues.includes(String(value)));
        return allValuesSelected;
    });

    // Retrieve all values within the selected groups
    const selectedGroupValues = selectedGroups.map((option) => option.value).flat();

    // Filter individual options that are selected but do not belong to any group
    const individualOptions = options.filter((option) => {
        if (Array.isArray(option.value)) return false;
        const isSelected = selectedValues.includes(String(option.value));
        const isInGroup = selectedGroupValues.includes(option.value);
        return isSelected && !isInGroup;
    });

    // Combine individual options with the selected groups
    return [...individualOptions, ...selectedGroups];
};
