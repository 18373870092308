import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA} from "appConstants";
import isNil from "lodash/isNil";
import {ConnectService} from "services/ConnectService";
import {TicketingService} from "services/TicketingService";
import {fetchTicket, IFetchTicketThunkResponse} from "store/ticketEditSlice";
import {TicketFollowerV1Attributes} from "typing/dto";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_FOLLOWER_DELETE, TICKET_FOLLOWER_ADD} = EVENT_LOG_DATA;

// Service Singletons
const ticketingService = TicketingService.getInstance();
const connectService = ConnectService.getInstance();

export type OptionFollower = {
    id: number;
    name: string;
};

export interface ITicketEditFollowersState {
    isFetching: boolean;
    followers: TicketFollowerV1Attributes[];
    isFillingFollowers: boolean;
    filledFollowers: OptionFollower[] | null;
    isRemovingIds: number[];
}

export interface IFillFollowersThunkResponse {
    followers: OptionFollower[];
}

interface UpdateFollowerThunkType {
    ticketId: number;
    follower: OptionFollower;
}

/**
 * Fetch users from logins endpoint in Connect Service and fill followers data
 */
export const fillFollowers = createAsyncThunk(
    "ticketEditFollower/fillFollowers",
    async (followers: TicketFollowerV1Attributes[]): Promise<IFillFollowersThunkResponse> => {
        const userIds: number[] = followers.map((follower) => follower.user_id);
        const users = await connectService.getUsers({id: userIds.toString()});
        return {
            followers: users.map((user) => ({id: Number(user.id), name: `${user.attributes.first_name} ${user.attributes.last_name}`})),
        };
    }
);

export const addFollower = createAsyncThunk(
    "ticketEditFollower/addFollower",
    async ({ticketId, follower}: UpdateFollowerThunkType, {rejectWithValue}) => {
        try {
            await ticketingService.addFollower(ticketId, follower.id);
            return follower;
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

export const removeFollower = createAsyncThunk(
    "ticketEditFollower/removeFollower",
    async ({ticketId, followerId}: {ticketId: number; followerId: number}, {rejectWithValue}) => {
        try {
            await ticketingService.removeFollower(ticketId, followerId);
            return followerId;
        } catch (error) {
            return rejectWithValue(getErrorMessage(error));
        }
    }
);

const ticketEditFollowerSlice = createSlice({
    name: "followerEdit",
    initialState: {
        isFetching: false,
        followers: [],
        isRemovingIds: [],
        isFillingFollowers: false,
        filledFollowers: null,
    } as ITicketEditFollowersState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addFollower.rejected, (_state: ITicketEditFollowersState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_FOLLOWER_ADD, error: Error(payload)});
            })
            .addCase(addFollower.fulfilled, (state: ITicketEditFollowersState, {payload}: {payload: OptionFollower}) => {
                state.filledFollowers = !isNil(state.filledFollowers) ? [...state.filledFollowers, payload] : [payload];
                enqueueSuccess({logInfo: TICKET_FOLLOWER_ADD, data: {payload}});
            })
            .addCase(removeFollower.pending, (state: ITicketEditFollowersState, {meta}) => {
                state.isRemovingIds = [...state.isRemovingIds, meta.arg.followerId];
            })
            .addCase(removeFollower.rejected, (state: ITicketEditFollowersState, {payload, meta}: {payload: any; meta: any}) => {
                state.isRemovingIds = state.isRemovingIds.filter((id) => id !== meta.arg.followerId);
                enqueueError({logInfo: TICKET_FOLLOWER_DELETE, error: Error(payload)});
            })
            .addCase(removeFollower.fulfilled, (state: ITicketEditFollowersState, {payload, meta}) => {
                state.filledFollowers = isNil(state.filledFollowers)
                    ? null
                    : state.filledFollowers.filter((follower: OptionFollower) => follower.id !== payload);
                state.isRemovingIds = state.isRemovingIds.filter((id) => id !== meta.arg.followerId);
                enqueueSuccess({logInfo: TICKET_FOLLOWER_DELETE, data: {payload}});
            })
            .addCase(fetchTicket.pending, (state: ITicketEditFollowersState) => {
                state.isFetching = true;
            })
            .addCase(fetchTicket.fulfilled, (state: ITicketEditFollowersState, {payload}: {payload: IFetchTicketThunkResponse}) => {
                state.isFetching = false;
                state.followers = payload.followers;
            })
            .addCase(fillFollowers.pending, (state: ITicketEditFollowersState) => {
                state.isFillingFollowers = true;
            })
            .addCase(fillFollowers.fulfilled, (state: ITicketEditFollowersState, {payload}: {payload: IFillFollowersThunkResponse}) => {
                state.isFillingFollowers = false;
                state.filledFollowers = payload.followers;
            });
    },
});

export default ticketEditFollowerSlice.reducer;
