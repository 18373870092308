import {DEFAULT_SORTING_VALUES, IFRAME_QUERY_PARAM, IFRAME_QUERY_PARAM_REDIRECT, SortOrderEnum} from "appConstants";
import {useSharedSearchParams} from "contexts/SearchParamsContext";
import {SearchSortOrder} from "typing/request";

export interface ISetURLParams {
    (newParams: {[key: string]: string}, removeStrict?: boolean): void;
}

const useURLParams = () => {
    const {searchParams, setSearchParams} = useSharedSearchParams();

    const defaultValues: Record<string, any> = {
        manager_id: "",
        assigned_to: "",
        follower_id: "",
        created_by: "",
        unitFilterId: "",
        "page[limit]": 10,
        sort: Object.keys(DEFAULT_SORTING_VALUES),
    };

    /**
     * Updates a URL search parameter with the provided value.
     *
     * @param newParams - key/value object to set new URL parameters.
     * @param removeStrict - if true will remove the query param. if false will keep it empty. e.g. `?manager_id=`
     *
     * @remarks
     * - Uses the `setSearchParams` function to update the URL.
     */
    const setURLParams: ISetURLParams = (newParams: {[key: string]: string}, removeStrict: boolean = true): void => {
        if (!newParams) return;
        setSearchParams((prevParams: URLSearchParams) => {
            Object.entries(newParams).forEach(([key, value]) => {
                if (value) {
                    prevParams.set(key, value);
                } else if (removeStrict) {
                    prevParams.delete(key);
                } else {
                    prevParams.set(key, "");
                }
            });
            return prevParams;
        });
    };

    /**
     * Retrieves all URL search parameters that belong to a specific category.
     * A category is identified by the prefix in the parameter name, such as:
     * "filter[manager_id]", "filter[assigned_to]", "filter[follower_id]", filter[unit_id]
     * It then extracts the parameter keys, removes the category prefix and square brackets,
     * and returns an object of key-value pairs:
     *
     * @param category - The category prefix to filter parameters by (e.g., 'filter').
     * @returns An object containing key-value pairs where keys are the URL parameter names
     *          (after the category and brackets have been removed) and values are the corresponding parameter values.
     *          e.g. {manager_id: 1, assigned_to: 2, follower_id: 3, unit_id: 4}
     */
    const getParamsByCategory = (category: string) => {
        // Convert searchParams.entries() to an array
        const paramsArray = Array.from(searchParams.entries());

        // Filter and transform the parameters that match the given category
        return Object.fromEntries(
            paramsArray
                .filter(([key]) => key.startsWith(category))
                .map(([key, value]) => {
                    const param = key.replace(`${category}[`, "").replace("]", "");
                    const newValue = value || defaultValues[key];
                    return [param, newValue];
                })
        );
    };

    /**
     * Adds or updates a value in a comma-separated list within a URL parameter.
     *
     * @param sortItem - The sort item to add or update in the URL.
     * e.g. `{ creation_date: 'asc'}`
     */
    const upsertSortParam = (sortItem: {[key: string]: SearchSortOrder | null}, removeStrict: boolean = true) => {
        const paramKey = "sort";

        const currentParamValues = searchParams.get(paramKey)?.split(",").filter(Boolean) || defaultValues.sort;
        const queryParamValue = _covertSortItem(sortItem);
        const [itemName] = Object.keys(sortItem);
        const paramIndex = currentParamValues.findIndex((param: string) => param.replace("-", "") === itemName);

        if (paramIndex !== -1) {
            if (sortItem[itemName]) {
                currentParamValues[paramIndex] = queryParamValue;
            } else {
                currentParamValues.splice(paramIndex, 1);
            }
        } else {
            currentParamValues.push(queryParamValue);
        }

        const newParamValue = currentParamValues.join(",") || "";

        setURLParams({[paramKey]: newParamValue}, removeStrict);
    };

    /**
     * Removes a value from a comma-separated list within a URL parameter.
     * e.g. `sort=-status,unit_code`
     *
     * @param sortItemName - The sort item name that should be removed.
     */
    const removeSortingParam = (sortItemName: string) => {
        const paramKey = "sort";

        const currentParamValues = searchParams.get(paramKey);
        const newParamValue =
            currentParamValues
                ?.split(",")
                .filter((param) => param.replace("-", "") !== sortItemName)
                .join(",") ?? "";
        setURLParams({[paramKey]: newParamValue});
    };
    /**
     * It gets the value of the specified query param.
     * e.g. `?title=-lorem ipsum` returns -> `lorem ipsum`
     * @returns paramName value
     */
    const getURLParam = (paramName?: string) => {
        return paramName ? searchParams.get(paramName) : null;
    };

    const iframeParam = getURLParam(IFRAME_QUERY_PARAM) === IFRAME_QUERY_PARAM_REDIRECT ? `${IFRAME_QUERY_PARAM}=${IFRAME_QUERY_PARAM_REDIRECT}` : "";
    const isBannerEnabled = Boolean(iframeParam);

    return {
        searchParams,
        getURLParam,
        setURLParams,
        getParamsByCategory,
        upsertSortParam,
        removeSortingParam,
        isBannerEnabled,
        iframeParam,
    };
};

/**
 * Transforms a `{ [key: string]: 'desc' | 'asc' }` object into a string representation.
 *
 * Example:
 * Given `sortItem` is `{ creation_date: 'asc', status: 'desc' }`, it will return `'creation_date,-status'`.
 *
 * @param sortItem - `{creation_date: 'asc', status: 'desc'}`
 * @returns A comma-separated string of properties with optional '-' prefix for descending order.
 *          e.g. `'-status,creation_date,-unit_code'`
 */
export const _covertSortItem = (sortItem: {[key: string]: SearchSortOrder} | {}): string => {
    return Object.entries(sortItem)
        .map(([property, order]) => {
            const prefix = order === SortOrderEnum.DESCENDING ? "-" : "";
            return order ? `${prefix}${property}` : "";
        })
        .join(",");
};

export default useURLParams;
