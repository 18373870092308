import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { RootState } from "store/store";
import { ReactComponent as IconTrash } from "assets/icons/trash.svg"
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { addFollower, removeFollower, fillFollowers, OptionFollower } from "store/ticketEditFollowersSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import find from "lodash/find";
import { Spinner } from "components/spinner/Spinner";
import { SearchAssignableUsersBar, UserItemType } from "components/search-assignable-users-bar/SearchAssignableUsersBar";
import { LoaderButton } from "components/loader-button/LoaderButton";
import { TicketFollowerV1Attributes, CurrentUserType } from "typing/dto";
import styles from './TicketEditFollowers.module.scss';

interface TicketEditFollowersProps {
    ticketId: number;
    followers: TicketFollowerV1Attributes[];
    currentUser: CurrentUserType
}

export const TicketEditFollowers: React.FC<TicketEditFollowersProps> = (
    {ticketId, followers, currentUser}
) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    // slices states
    const {
        filledFollowers,
        isFillingFollowers,
        isRemovingIds
    } = useSelector((state: RootState) => state.ticketEditFollowers);

    // internal states
    const [followerToAdd, setFollowerToAdd] = useState<UserItemType | null>(null);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [isSelfUpdating, setIsSelfUpdating] = useState<boolean>(false);
    const currentUserIsFollower = isNil(currentUser) || isNil(filledFollowers) ? null : !isNil(find(filledFollowers, ['id', currentUser.id]));

    // validations
    const isInvalid = isNil(followerToAdd);

    // Fetch data from Connect to fill followers information
    useEffect(() => {
        if (!isEmpty(followers)) dispatch(fillFollowers(followers));
    }, [followers, dispatch])

    const handleFollowerAdd = async () => {
        if (isNil(followerToAdd)) return; // no follower then quit

        setIsAdding(true);
        await dispatch(addFollower({ticketId, follower: {id: followerToAdd.id, name: `${followerToAdd.firstName} ${followerToAdd.lastName}`}}));
        setFollowerToAdd(null);
        setIsAdding(false);
    }

    const handleFollowerRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const userId = parseInt(event.currentTarget.dataset.followerId || '', 10);
        await dispatch(removeFollower({ticketId, followerId: userId}));
    }

    const handleSelfFollowerUpdate = async () => {
        if (isNil(currentUser)) return; // no current user then quit

        setIsSelfUpdating(true);
        if (currentUserIsFollower) await dispatch(removeFollower({ticketId, followerId: currentUser.id}));
        else await dispatch(addFollower({ticketId, follower: {id: currentUser.id, name: `${currentUser.firstName} ${currentUser.lastName}`}}));
        setIsSelfUpdating(false);
    }

    const Header = (
        <div>
            <h2>Follow Ticket</h2>
            {
                !isNil(currentUserIsFollower) && (
                    <span>
                        <button
                            onClick={handleSelfFollowerUpdate}
                            className="btn-link"
                            data-testid="self-update-button"
                            disabled={isSelfUpdating}
                        >
                            {currentUserIsFollower ? "Remove Me" : "Add Me"}
                        </button>{isSelfUpdating && <span className={styles["self-spinner"]}><Spinner /></span>}
                    </span>
                )
            }

        </div>
    );

    // Display loader until
    if (isFillingFollowers) {
        return (
            <TicketBoxCollapsable header={Header}>
                <Spinner/>
            </TicketBoxCollapsable>
        );
    }

    return (
        <TicketBoxCollapsable header={Header}>
            <div className={styles.editFollowerEditContainer}>
                <h4>ADD FOLLOWER</h4>
                <SearchAssignableUsersBar
                    onSelected={setFollowerToAdd}
                    disabled={isAdding}
                    disabledUsers={isNil(filledFollowers) ? [] : filledFollowers}
                />
                <div className="editFollowerEditActions">
                    <LoaderButton
                        id={"add-follower-button-submit"}
                        variant="primary"
                        disabled={isInvalid}
                        onClick={handleFollowerAdd}
                        className={styles["add-follower-btn"]}
                    >
                        Add
                    </LoaderButton>
                </div>
            </div>
            {
                !isNil(filledFollowers) && (
                    <div className={styles.followerList} data-testid="followers-list">
                        <h4>FOLLOWERS</h4>
                        <ul>
                            {!isFillingFollowers && filledFollowers.map( (follower: OptionFollower, index: number) => (<li key={index} data-testid="follower-item">
                                {follower.name}{follower.id === currentUser?.id && (" (Me)")}
                                {isRemovingIds.includes(follower.id) ?
                                    <span className={styles["remove-spinner"]}><Spinner /></span> :
                                    <button
                                        className="btn-chip"
                                        data-follower-id={follower.id}
                                        onClick={handleFollowerRemove}
                                    >
                                        <IconTrash className="icon-bg" />
                                    </button>
                                }
                            </li>))}
                        </ul>
                    </div>
                )
            }
        </TicketBoxCollapsable>
    );
}
