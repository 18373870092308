import {MenuProps} from "@material-ui/core";
import {HostApplication, HostApplicationBuilderTypes} from "appConstants";
import {env} from "core/env";
import isEmpty from "lodash/isEmpty";
import {AdminService} from "services/AdminService";
import {TicketSearchRow} from "typing";

export function truncateFilename(filename: string, maxLength: number): string {
    const fileParts: string[] = filename.split(".");
    const extension: string = fileParts.pop() || "";
    const name = fileParts.join(".");
    if (name.length + extension.length + 1 <= maxLength) return filename;

    const charsToShow = maxLength - extension.length - 3;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    // TODO: deprecated
    return name.substr(0, frontChars) + "..." + name.substr(name.length - backChars) + "." + extension;
}

// This will fix combobox menu items positioning https://stackoverflow.com/questions/57815745/positioning-of-menuitems-under-the-material-ui-select-component
export const muiMenuBottomProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    getContentAnchorEl: null,
} as Partial<MenuProps>;

/**
 * Check if we are currently living within an iframe
 * This is helpful to patch some unexpected behavior around modals
 */
export function isIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

/**
 * Async scroll into an element
 * @param elementId
 * @returns
 */
export const iframeScrollIntoElement = (elementId: string) => {
    if (!isIframe()) return; // prevent if not an iframe
    setTimeout(() => document.getElementById(elementId)?.scrollIntoView(), 10);
};

/**
 * Function to slugify username text
 *
 * e.g: john.dóe to john.doe
 *
 * @param {string} username
 * @returns {string}
 */
export const slugifyUsername = (username: string) => {
    return username.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

/**
 * Returns Url generators for Admin & Salesforce
 */
export const hostAppUrlBuilders: Record<HostApplicationBuilderTypes, (id: number | string) => string> = {
    [HostApplicationBuilderTypes.ADMIN_LEGACY]: (ticketId) => `${env.REACT_APP_ADMIN_URL}/admin/dashboard/tickets/view?TicketID=${ticketId}`,
    [HostApplicationBuilderTypes.ADMIN]: (ticketId) => `${env.REACT_APP_ADMIN_URL}/admin/dashboard/ticket/${ticketId}`,
    [HostApplicationBuilderTypes.SALESFORCE]: (caseId) => `${env.REACT_APP_SALESFORCE_URL}/lightning/r/Case/${caseId}/view`,
};

/**
 * Opens a new tab with the Ticket in Admin/Salesforce depending where
 * this iframe is embbeded.
 */
export const goToTicket = (row: TicketSearchRow) => {
    const currentParent = window.name || "";
    let urlTarget = `/ticket/${row.id}/edit`;

    if (currentParent === HostApplication.SALESFORCE && !isEmpty(row.caseId)) {
        urlTarget = hostAppUrlBuilders.salesforce(row.caseId);
    } else if (currentParent === HostApplication.ADMIN || (currentParent === HostApplication.SALESFORCE && isEmpty(row.caseId))) {
        urlTarget = hostAppUrlBuilders.admin_legacy(row.id);
    }
    window.open(urlTarget, "_blank");
};

/**
 * Opens a new tab to Reservation in Admin related to this ticket using reservationId field in the
 * TicketSearchRow object
 * @param row TicketSearchRow
 */
export const goToReservation = (row: TicketSearchRow, event?: React.MouseEvent<any, MouseEvent>): void => {
    if (event) event.stopPropagation();
    window.open(AdminService.getReservationUrl(row.reservationId), "_blank");
};

export const isSalesforce = () => window.name === HostApplication.SALESFORCE;

export const isAdmin = () => window.name === HostApplication.ADMIN;
